<form [formGroup]="form">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.spinningReserves.market.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Energy Vector -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="market-form-ev-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.activation.labels.energyVector'
              | translate
          }}</mat-label>

          <mat-select
            data-testid="market-form-ev-option"
            formControlName="energyVectorId"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.activation.placeholders.energyVector'
                | translate
            "
          >
            <mat-option
              *ngFor="let energyVector of data.energyVectorOptions"
              [value]="energyVector.value"
            >
              {{ energyVector.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="market-form-ev-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.reserve_energy_vector'"
          >
            help
          </mat-icon>
          <mat-error
            data-testid="market-form-ev-error"
            *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.energyVector.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'dataExist'"
              [style.line-height.px]="12"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.activation.errors.energyVector.dataExist'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Market -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="market-form-market-title"
            >{{
              'Scenario.dialog.frequencyControl.spinningReserves.market.labels.market'
                | translate
            }}
          </mat-label>

          <mat-select
            data-testid="market-form-market-option"
            formControlName="energyGridConnectionId"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.market.placeholders.market'
                | translate
            "
          >
            <mat-option
              *ngFor="let market of data.marketOptions"
              [value]="market.id"
            >
              {{ market.marketName }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="market-form-market-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.market'"
          >
            help
          </mat-icon>
          <mat-error
            data-testid="market-form-market-error"
            *ngFor="let error of marketIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.market.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'dataExist'"
              [style.line-height.px]="12"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.market.dataExist'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Direction -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="market-form-direction-title"
            >{{
              'Scenario.dialog.frequencyControl.spinningReserves.market.labels.direction'
                | translate
            }}
          </mat-label>

          <mat-select
            data-testid="market-form-direction-option"
            formControlName="direction"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.market.placeholders.direction'
                | translate
            "
          >
            <mat-option
              *ngFor="let direction of sortedDirections"
              [value]="direction.key"
            >
              {{ direction.value }}
            </mat-option>
          </mat-select>

          <mat-icon
            aria-hidden="false"
            data-testid="market-form-direction-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.direction'"
          >
            help
          </mat-icon>

          <mat-error
            data-testid="market-form-direction-error"
            *ngFor="let error of directionControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.direction.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Participation -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="market-form-participation-title"
            >{{
              'Scenario.dialog.frequencyControl.spinningReserves.market.labels.participation'
                | translate
            }}
          </mat-label>

          <mat-select
            data-testid="market-form-participation-option"
            formControlName="participation"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.market.placeholders.participation'
                | translate
            "
          >
            <mat-option
              *ngFor="let participation of sortedParticipations"
              [value]="participation.key | coerceBoolean"
            >
              {{ participation.value }}
            </mat-option>
          </mat-select>

          <mat-icon
            aria-hidden="false"
            data-testid="market-form-participation-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_frequency_control.market_participation' | translate
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="market-form-participation-error"
            *ngFor="let error of participationControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.participation.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Requirement -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="market-form-requirement-title">
            {{
              'Scenario.dialog.frequencyControl.spinningReserves.market.labels.requirement'
                | translate
            }}
          </mat-label>

          <input
            data-testid="market-form-requirement-input"
            matInput
            formControlName="requirement"
            required
            type="number"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.market.placeholders.requirement'
                | translate
            "
          />

          <mat-icon
            aria-hidden="false"
            data-testid="market-form-requirement-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_frequency_control.market_requirement' | translate
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="market-form-requirement-error"
            *ngFor="let error of requirementControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.requirement.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.market.errors.requirement.min'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.currentReserveMarket?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>

    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'market-form-cancel'"
    >
      <span
        [attr.data-testid]="'market-form-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'market-form-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>
