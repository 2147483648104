<form
  [formGroup]="uploadForm"
  fxLayout="column"
  fxLayoutGap="5px"
  #ngForm
  (ngSubmit)="onSubmit()"
>
  <!-- <mat-form-field class="eyes-maximize-width" appearance="outline" [@fadeIn]>
    <mat-label>{{ 'Scenario.upload.inputName' | translate }}</mat-label>
    <input
      data-testid="scenario-upload-name-input"
      matInput
      [placeholder]="'Scenario.upload.inputName' | translate"
      formControlName="name"
      required
    />
    <mat-error
      *ngFor="let error of getErrors(uploadForm?.controls?.name?.errors)"
      [innerHtml]="inputNameErrorMessages()[error]"
    >
    </mat-error>
  </mat-form-field>
  <mat-form-field class="eyes-maximize-width" appearance="outline" [@fadeIn]>
    <mat-label>{{ 'Scenario.upload.inputDescription' | translate }}</mat-label>
    <input
      data-testid="scenario-upload-description-input"
      matInput
      [placeholder]="'Scenario.upload.inputDescription' | translate"
      formControlName="description"
    />
  </mat-form-field> -->

  <prosumer-upload-form-list
    [multipleFiles]="true"
    [hideTableHeader]="false"
    [hideTableMessages]="false"
    [columnDef]="columnDef"
    [customTableDataActive]="true"
    [setCustomTableData]="tableData()"
    [acceptedFiles]="allowedFileExtns"
    [formTitle]="'Scenario.upload.inputFileForm.formTitle' | translate"
    (valueChanged)="inputFilesChanged($event)"
  ></prosumer-upload-form-list>

  <p class="mat-caption">
    {{ 'Scenario.information.missingTemplatePart1' | translate }}
    <a
      data-testid="scenario-download-link"
      class="eyes-href-bold custom-download-template-link"
      (click)="getXlsxlTemplate()"
    >
      {{ 'Scenario.information.missingTemplateLink' | translate }}
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        tooltipPosition="center"
        aria-label="Download Excel Template"
        title="Download Excel Template"
      >
        save_alt
      </mat-icon>
    </a>
    {{ 'Scenario.information.missingTemplatePart2' | translate }}
  </p>

  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start left">
    <button
      mat-flat-button
      class="eyes-button"
      color="primary"
      [disabled]="isLoading || uploadForm?.invalid"
      data-testid="scenario-upload-simulate-button"
    >
      {{ 'Generic.labels.simulate' | translate }}
    </button>
    <button
      data-testid="scenario-upload-cancel-button"
      class="eyes-button"
      color=""
      mat-flat-button
      type="button"
      [disabled]="isLoading"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
