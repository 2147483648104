<div
  *ngIf="storageOptions && storageOptions.length > 0"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
    <span class="storage-dispatch-title">{{
      'Result.labels.storage' | translate
    }}</span>
    <prosumer-select
      fxFlex
      [placeholder]="'Type'"
      [label]="'Type'"
      [control]="storageControl"
      [options]="storageOptions"
      [attr.data-testid]="'dispatch-raw-storage-filter'"
    ></prosumer-select>
  </div>

  <div *ngIf="loading$ | async" class="prosumer-dispatch-spinner">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <mat-spinner color="accent" diameter="25"></mat-spinner>
      <span>{{ 'Result.messages.loadingChart' | translate }}...</span>
    </div>
  </div>

  <prosumer-stacked-bar-chartjs
    *ngIf="(storageSoc$ | async) && (storageCharge$ | async)"
    [data]="chartData$ | async"
    [line]="lineData$ | async"
    [colors]="colors$ | async"
    [enableXlsxExport]="true"
    scenarioName="{{ scenarioName }}"
    resultsName="storage_raw_dispatch"
  ></prosumer-stacked-bar-chartjs>
</div>
