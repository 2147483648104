import { Injectable } from '@angular/core';
import { ScenarioInfoService } from 'prosumer-app/+scenario/services';
import {
  CascadableEntity,
  CascadeAction,
  CascadeController,
} from 'prosumer-app/services/cascade-controller';
import { ScenarioDetailType } from 'prosumer-app/stores/scenario-detail';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimeHorizonService {
  constructor(
    private readonly controller: CascadeController,
    private readonly shittyInfo: ScenarioInfoService,
  ) {}

  updateProjectDuration(data: unknown = {}): Observable<unknown> {
    return this.controller.confirmIfApplicable(this.buildTargetEntity()).pipe(
      map((confirmed) => this.coerceToBoolean(confirmed)),
      mergeMap((confirmed) => this.eitherUpdateOrNot(confirmed, data)),
    );
  }

  private coerceToBoolean(value: unknown): boolean {
    return !!value;
  }

  private eitherUpdateOrNot(
    confirmed: boolean,
    data: unknown,
  ): Observable<unknown> {
    return confirmed ? this.shittyInfo.updateScenario(data, true) : of(false);
  }

  private buildTargetEntity(): CascadableEntity {
    return {
      action: CascadeAction.edit,
      type: ScenarioDetailType.projectDuration,
    };
  }
}
