<form [formGroup]="vehiclesDispatchForm" class="form">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{ 'Scenario.labels.wizard.mobility.headers.vehiclesDispatch' | translate }}
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Vehicle Name -->
        <prosumer-input
          type="string"
          module="Scenario"
          class="eyes-maximize-width"
          formFieldName="mobility.vehiclesDispatch.vehicleName"
          [control]="vehiclesDispatchForm?.controls?.vehicleName"
          [attr.data-testid]="'vehicle-dispatch-form-vehicle-name'"
          [inputTooltip]="
            'wizard_mobility.wizard_mobility_dispatch_vehicle_name'
          "
          [required]="true"
        ></prosumer-input>
        <prosumer-select
          id="vehicleId"
          class="eyes-maximize-width"
          [label]="
            'Scenario.labels.mobility.vehiclesDispatch.vehicleTechnology'
              | translate
          "
          [placeholder]="
            'Scenario.placeholders.mobility.vehiclesDispatch.vehicleTechnology'
              | translate
          "
          [control]="vehiclesDispatchForm?.controls?.vehicleId"
          [options]="vehicleTechnologyOptions"
          [errorMessageMap]="errorMessages?.vehicleId"
          [required]="true"
          data-testid="vehicle-dispatch-form-vehicle-technology"
        ></prosumer-select>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Route IDs Input -->
        <prosumer-generic-filterchip-component
          fxFlex="100%"
          [label]="
            'Scenario.labels.mobility.vehiclesDispatch.routeIds' | translate
          "
          [placeholder]="
            'Scenario.placeholders.mobility.vehiclesDispatch.routeIds'
              | translate
          "
          [enableSelectAll]="false"
          [tooltip]="'wizard_mobility.wizard_mobility_dispatch_route'"
          [required]="true"
          [genericOptions]="routeIdsOptions"
          formControlName="routeIds"
          [errorMessage]="errorMessages?.routeIds"
          [setSubmitted]="submitted$ | async"
          data-testid="vehicle-dispatch-form-routes"
        >
        </prosumer-generic-filterchip-component>
        <ng-template #single>
          <div fxFlex="100%"></div>
        </ng-template>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-select
          type="number"
          id="startYear"
          [label]="
            'Scenario.labels.mobility.vehiclesDispatch.startYear' | translate
          "
          [placeholder]="
            'Scenario.placeholders.mobility.vehiclesDispatch.startYear'
              | translate
          "
          [control]="vehiclesDispatchForm?.controls?.startYear"
          [options]="yearsOptions"
          [errorMessageMap]="errorMessages?.startYear"
          [required]="true"
          data-testid="vehicle-dispatch-form-start-year"
        ></prosumer-select>
        <prosumer-select
          type="number"
          id="endYear"
          [label]="
            'Scenario.labels.mobility.vehiclesDispatch.endYear' | translate
          "
          [placeholder]="
            'Scenario.placeholders.mobility.vehiclesDispatch.endYear'
              | translate
          "
          [control]="vehiclesDispatchForm?.controls?.endYear"
          [options]="yearsOptions"
          [errorMessageMap]="errorMessages?.endYear"
          [required]="true"
          data-testid="vehicle-dispatch-form-end-year"
        ></prosumer-select>
        <prosumer-input
          type="number"
          module="Scenario"
          class="eyes-maximize-width"
          formFieldName="mobility.vehiclesDispatch.numberOfVehicles"
          [inputTooltip]="
            'wizard_mobility.wizard_mobility_dispatch_number_of_vehicles'
          "
          [placeholder]="
            'Scenario.placeholders.mobility.vehiclesDispatch.numberOfVehicles'
              | translate
          "
          [label]="
            'Scenario.labels.mobility.vehiclesDispatch.numberOfVehicles'
              | translate
          "
          [control]="vehiclesDispatchForm?.controls?.numberOfVehicles"
          [errorMessageMap]="errorMessages?.numberOfVehicles"
          [required]="true"
          data-testid="vehicle-dispatch-form-no-of-vehicles"
        ></prosumer-input>
      </div>
    </div>
  </div>

  <div
    mat-dialog-actions
    fxLayout="column"
    fxLayoutGap="10px"
    fxLayoutAlign="space-around stretch"
  >
    <mat-checkbox
      type="checkbox"
      [checked]="v2g"
      (change)="updateV2g($event)"
      data-testid="vehicle-dispatch-v2g"
    >
      {{ 'Scenario.placeholders.mobility.vehiclesDispatch.v2g' | translate }}
    </mat-checkbox>
    <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
      <prosumer-entity-upsert-button
        *ngIf="!isViewOnly"
        [id]="data?.id"
        [disabled]="vehiclesDispatchForm?.pristine"
        [data]="getFormValues()"
        [mode]="data?.mode"
        [valid]="vehiclesDispatchForm?.valid"
        (ok)="onClose()"
        (attempt)="onSaveAttempt()"
      ></prosumer-entity-upsert-button>
      <button
        mat-flat-button
        type="button"
        data-testid="vehicle-dispatch-form-cancel-btn"
        color=""
        (click)="onClose()"
      >
        <span *ngIf="!isViewOnly; else closeButton">{{
          data?.close || ('Generic.labels.cancel' | translate)
        }}</span>
        <ng-template #closeButton>
          <span>{{ 'Generic.labels.close' | translate }}</span>
        </ng-template>
      </button>
    </div>
  </div>
</form>
