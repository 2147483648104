<form fxLayout="column" fxLayoutGap="4px" [formGroup]="caseForm">
  <div
    *ngIf="loading; else showCaseDetails"
    fxLayout="column"
    fxLayoutGap="10px"
    [@fadeIn]
  >
    <ngx-skeleton-loader class="shorter"></ngx-skeleton-loader>
    <div fxLayout="column">
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="eyes-maximize-width"></ngx-skeleton-loader>
    </div>
  </div>

  <ng-template #showCaseDetails>
    <div fxLayout="row" fxLayoutAlign="start center">
      <!-- Case Name -->
      <h2
        data-testid="case-view-name"
        *ngIf="mode === 'read'; else showInputName"
        class="eyes-remove-margin"
        [@fadeIn]
      >
        {{ caseForm?.controls?.name?.value }}
      </h2>
      <ng-template #showInputName>
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="case-form-case-name-input-label">{{
            'Case.labels.name' | translate
          }}</mat-label>
          <input
            matInput
            required
            formControlName="name"
            [placeholder]="'Case.placeholders.name' | translate"
            [readonly]="mode === 'read'"
            data-testid="case-form-name"
          />
          <mat-icon
            aria-hidden="false"
            class="eyes-input-tooltip-anchor"
            matSuffix
            tooltipPosition="left"
            [prosumerTooltip]="'cases.case_name'"
            data-testid="case-form-case-name-help"
            >help</mat-icon
          >
          <mat-error
            *ngFor="let error of getErrors(caseForm?.controls.name)"
            [innerHTML]="'Case.messages.name.' + error | translate"
            [attr.data-testid]="'case-form-name-' + error"
          ></mat-error>
          <!-- <mat-error
            *ngIf="caseForm?.controls?.name?.errors?.required"
            [innerHtml]="'Case.messages.name.required' | translate"
            data-testid="case-form-name-required"
          ></mat-error>
          <mat-error
            *ngIf="caseForm?.controls?.name?.errors?.invalidCharacter"
            [innerHtml]="'Case.messages.name.invalid' | translate"
            data-testid="case-form-name-invalid"
          ></mat-error>
          <mat-error
            *ngIf="caseForm?.controls?.name?.errors?.whiteSpaces"
            [innerHtml]="'Case.messages.name.invalid' | translate"
            data-testid="case-form-name-invalid"
          ></mat-error> -->
        </mat-form-field>
      </ng-template>
      <!-- Edit button toggle -->
      <a
        data-testid="edit-case-button"
        class="eyes-inline-icon-button"
        *ngIf="mode === 'read' && canEdit"
        mat-icon-button
        color="primary"
        (click)="onEdit()"
        [@fadeIn]
      >
        <mat-icon aria-hidden="false" inline>edit</mat-icon>
      </a>
    </div>
    <!-- Description READONLY -->
    <p
      *ngIf="mode === 'read'; else showInputDescription"
      class="eyes-remove-margin case-form-case-description"
      [@fadeIn]
      data-testid="case-form-case-description"
    >
      {{ caseForm?.controls?.description?.value }}
    </p>
    <!-- Description EDIT -->
    <ng-template #showInputDescription>
      <mat-form-field
        class="eyes-maximize-width"
        appearance="outline"
        [@fadeIn]
      >
        <mat-label>{{ 'Case.labels.description' | translate }}</mat-label>
        <textarea
          matInput
          [placeholder]="'Case.placeholders.description' | translate"
          formControlName="description"
          rows="8"
          [readonly]="mode === 'read'"
          data-testid="case-form-description-edit"
        ></textarea>
        <mat-icon
          aria-hidden="false"
          class="eyes-input-tooltip-anchor"
          matSuffix
          tooltipPosition="left"
          [prosumerTooltip]="'cases.case_description'"
          data-testid="case-form-case-description-help"
          >help</mat-icon
        >
      </mat-form-field>
    </ng-template>
    <!-- Node Type READONLY -->
    <p
      *ngIf="mode === 'read' || mode === 'update'; else showInputNodeType"
      data-testid="case-view-node"
    >
      <strong data-testid="case-form-node-type-label">Node Type:</strong>
      {{ caseForm?.controls?.nodeType?.value | titlecase }}
    </p>
    <!-- Node type EDIT-->
    <ng-template #showInputNodeType>
      <prosumer-radio-button
        ngif
        label="Node Type"
        [labelTooltip]="'cases.case_node_type'"
        [control]="caseForm?.controls?.nodeType"
        [options]="nodeTypeOptions"
        data-testid="case-form-node"
      >
      </prosumer-radio-button>
    </ng-template>
    <!-- Default Economics READONLY -->
    <!-- Default Economics (WACC) EDIT -->
    <div fxLayout="column" fxLayoutGap="10px">
      <strong
        class="prosumer-default-economics"
        data-testid="case-form-def-econ"
        >Default Economics<prosumer-tooltip-anchor
          [message]="'cases.case_default_economics'"
          data-testid="case-form-def-econ-tooltip"
        ></prosumer-tooltip-anchor>
      </strong>
      <!-- WACC -->
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="25%">
          <mat-form-field
            class="eyes-maximize-width"
            appearance="outline"
            [@fadeIn]
          >
            <mat-label data-testid="case-form-real-wacc-label">{{
              'Case.labels.wacc' | translate
            }}</mat-label>
            <input
              data-testid="case-form-wacc"
              matInput
              formControlName="wacc"
              required
              [readonly]="mode === 'read'"
              type="number"
            />
            <mat-icon
              aria-hidden="false"
              class="eyes-input-tooltip-anchor"
              matSuffix
              tooltipPosition="left"
              [prosumerTooltip]="'cases.case_real_wacc'"
              data-testid="case-form-case-real-wacc-help"
              >help</mat-icon
            >
            <mat-error
              *ngIf="caseForm?.controls?.wacc?.errors?.required"
              [innerHtml]="'Case.messages.wacc.required' | translate"
              data-testid="case-form-wacc-required"
            ></mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="25%"></div>
        <div fxFlex="25%"></div>
        <div fxFlex="25%"></div>
      </div>
    </div>
  </ng-template>

  <ng-content *ngIf="mode !== 'update'"></ng-content>

  <div *ngIf="mode !== 'create'" class="mat-caption">
    <span fxFlex></span>
    <ngx-skeleton-loader
      class="shorter"
      *ngIf="!owner; else showOwner"
      [@fadeIn]
    ></ngx-skeleton-loader>
    <ng-template #showOwner>
      <span [@fadeIn]
        >Created by {{ owner }} on
        {{ caseForm?.controls?.createdDate?.value | dateFormat }}</span
      >
    </ng-template>
  </div>

  <div
    *ngIf="mode !== 'read'"
    class="eyes-spacer-top-half"
    fxLayout="row"
    fxLayoutGap="10px"
    [@fadeIn]
  >
    <button
      data-testid="case-form-create"
      class="eyes-button"
      color="primary"
      mat-flat-button
      [disabled]="caseForm?.pristine || loading || saving"
      (click)="onSubmit()"
    >
      {{ mode === 'update' ? 'Update' : 'Create' }}
    </button>
    <button
      data-testid="case-form-cancel"
      class="eyes-button"
      color=""
      mat-flat-button
      type="button"
      [disabled]="loading || saving"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
