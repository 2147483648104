<prosumer-page>
  <prosumer-progress-card [showProgress]="uploading$ | async">
    <div fxLayout="row wrap-reverse">
      <prosumer-upload-form
        [isLoading]="uploading$ | async"
        [columnDef]="scenarioInputColumnDef"
        (cancel)="onCancel()"
        (save)="onCreate($event)"
      >
      </prosumer-upload-form>
      <prosumer-upload-form-list
        fxFlex="400px"
        [acceptedFiles]="'.gms'"
        [columnDef]="gamsColumnDef"
        [multipleFiles]="true"
        [formTitle]="'Scenario.upload.gams.formTitle' | translate"
        [infoMessage]="'Scenario.messages.gamsUploadWarning' | translate"
        (valueChanged)="gamsFilesChanged($event)"
      ></prosumer-upload-form-list>
    </div>
  </prosumer-progress-card>
</prosumer-page>
