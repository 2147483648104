<form [formGroup]="fuelForm" (ngSubmit)="onConfirm()">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Commodity (Fuel)
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <!-- Fuel and Node Inputs-->
      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Fuel Input -->
        <prosumer-select
          class="eyes-maximize-width"
          [placeholder]="'Scenario.placeholders.fuels.fuel' | translate"
          [tooltip]="'wizard_commodities.wizard_commodities_fuel_vector'"
          [label]="'Scenario.labels.fuels.fuel' | translate"
          [options]="data?.energyVectorOptions"
          [control]="fuelForm?.controls?.fuel"
          [required]="true"
          [errorMessageMap]="errorMessages['fuel']"
          data-testid="fuel-form-dialog-energy-vector"
        >
        </prosumer-select>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Node Input -->
        <prosumer-generic-filterchip-component
          [tooltip]="
            'wizard_commodities.wizard_commodities_fuel_node' | translate
          "
          [placeholder]="
            'Scenario.placeholders.energyGridLimits.node' | translate
          "
          [label]="'Scenario.labels.energyGridLimits.node' | translate"
          class="eyes-maximize-width"
          *ngIf="data?.isMultiNode"
          [required]="true"
          [errorMessage]="errorMessages.node"
          formControlName="generics"
          [enableSelectAll]="true"
          [genericOptions]="data?.nodeOptions"
          [setSubmitted]="submitted$ | async"
          [attr.data-testid]="'fuel-form-dialog-node-input'"
        >
        </prosumer-generic-filterchip-component>
      </div>
      <!-- Fuel Cost and CO2 Rate Inputs -->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Fuel Cost -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="fuelCost"
          inputLabel="Scenario.labels.fuels.fuelCost"
          errorMessage="Scenario.messages.fuelCost"
          contextHelpMsg="wizard_commodities.wizard_commodities_fuel_cost"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          [attr.data-testid]="'fuel-form-dialog-fuel-cost'"
          [isViewOnly]="data?.isViewOnly"
        >
        </prosumer-yearly-chart-input>
        <!-- CO2 Scope 1 Emission Factor -->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="co2Rate"
          inputLabel="Scenario.labels.fuels.co2Rate"
          errorMessage="Scenario.messages.co2Rate"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          [attr.data-testid]="'fuel-form-dialog-co2-scope1-emission-factor'"
          [isViewOnly]="data?.isViewOnly"
        >
        </prosumer-yearly-chart-input>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- CO2 scope3 emission factor-->
        <prosumer-yearly-chart-input
          fxFlex="49.9%"
          formControlName="co2Scope3EmissionFactor"
          inputLabel="Scenario.labels.fuels.co2Scope3EmissionFactor"
          errorMessage="Scenario.messages.co2Scope3EmissionFactor"
          [startYear]="data?.startYear"
          [endYear]="data?.endYear"
          [attr.data-testid]="'fuel-form-dialog-co2-scope3-emission-factor'"
          [isViewOnly]="data?.isViewOnly"
        >
        </prosumer-yearly-chart-input>
        <div fxFlex="49.9%"></div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      *ngIf="!data?.isViewOnly"
      mat-flat-button
      type="submit"
      color="primary"
      [class.spinner]="saving$ | ngrxPush"
      [class.unclickable]="saving$ | ngrxPush"
      [disabled]="!hasValidChanges() || (saving$ | ngrxPush)"
      data-testid="fuelform-dialog-ok"
    >
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
      data-testid="fuelform-dialog-cancel"
      [disabled]="saving$ | ngrxPush"
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
