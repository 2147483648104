<form (ngSubmit)="onConfirm()" fxLayout="column">
  <h2>Modify Yearly Values</h2>

  <div fxLayout="row" fxLayoutGap="14px" *ngrxLet="sanitized$ as yearlyValues">
    <prosumer-yearly-line-chart
      class="eyes-spacer-top-x2"
      xAxisLabel="Years"
      yAxisLabel="Value"
      [yearlyValuesMap]="yearlyValues"
    >
    </prosumer-yearly-line-chart>
    <div class="range-input" fxFlex>
      <p>Input Range</p>
      <prosumer-range-input
        [start]="startYear"
        [end]="endYear"
        [yearlyValues]="yearlyValues"
        (result)="rangeValueChange($event)"
        [isViewOnly]="data?.isViewOnly"
        [minValue]="data?.minValue"
        [notRequired]="data?.notRequired"
      >
      </prosumer-range-input>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row">
    <button
      *ngIf="!data?.isViewOnly"
      mat-flat-button
      type="submit"
      data-testid="dialog-ok"
      color="primary"
      [disabled]="disabled$ | ngrxPush"
    >
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      mat-flat-button
      type="button"
      color=""
      data-testid="dialog-cancel"
      (click)="onClose()"
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
