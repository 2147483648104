import { Environment } from 'prosumer-app/app.model';
import { API_CONFIG, ENV_CONFIG } from 'prosumer-app/libs/eyes-core';

import { Provider } from '@angular/core';

import { endpoints, version } from './environment-commons';

export const environment: Environment = {
  name: 'dev',
  production: false,
  cognito: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_Rq2gTdc9b',
      userPoolWebClientId: '5bdfn10iapgm9t1a4gaetmsgfb',
      userPoolWebClientSecret: '',
      oauth: {
        domain: 'prosumer-dev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://dev.prosumer.te-ded.com/dashboard',
        redirectSignOut: 'https://dev.prosumer.te-ded.com/home',
        responseType: 'code',
        options: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          AdvancedSecurityDataCollectionFlag: true,
        },
        identityProvider: 'prosumer-dev-idp',
      },
    },
  },
  http: {
    timeoutMs: 300000,
    maxRetryAttempts: 2,
    retryScalingDuration: 1000,
    retryIncludedStatusCodes: [401],
    refreshTokenIncludedStatusCodes: [401],
  },
  api: {
    baseUrl: 'https://api.dev.prosumer.te-ded.com/',
    endpoints,
  },
  version,
  websocket: {
    url: 'wss://wss.dev.prosumer.te-ded.com',
    keepAlive: true,
    keepAliveIntervalMs: 480000,
    retryOnClose: true,
    retryOnError: true,
    retryScalingDuration: 5000,
  },
  sentryDsn:
    'https://8575b50281874fe0a76fc6e5375c38c6@o258615.ingest.sentry.io/5745743',
  sentryRelease: `prosumer-${version.version}`,
  homeUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Website/D2/index.aspx',
  faqUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20FAQ/D2/index.aspx',
  trainingUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Video/D2/index.aspx',
  updatesUrl:
    'https://engie.sharepoint.com/sites/TETDataViz/Shared%20Documents/Prosumer%20Updates/D2/index.aspx',
  amplitudeAPIKey: 'c3a4555885794a49af5af52392a04a56',
};

export const provideEnvironmentConfig = (): Provider => {
  return { provide: ENV_CONFIG, useValue: environment };
};

export const provideApiConfig = (): Provider => {
  return { provide: API_CONFIG, useValue: endpoints };
};
