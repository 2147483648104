<div
  [ngClass]="{
    'error-class':
      controls?.nodes?.errors &&
      (inputNodeControl?.touched ||
        inputNodeControl?.dirty ||
        (submitted$ | async))
  }"
>
  <mat-form-field
    appearance="outline"
    class="eyes-maximize-width filterchip hide-subscript"
  >
    <mat-label>Select Nodes</mat-label>
    <mat-chip-grid #chipList aria-label="Nodes" [required]="required">
      <mat-chip-row
        [color]="'primary'"
        *ngFor="let node of selectedNodes"
        (removed)="remove(node)"
        selected
      >
        {{ node.name }}
        <mat-icon aria-hidden="false" matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        #nodeInput
        placeholder="Select Nodes"
        [formControl]="inputNodeControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
    <mat-icon
      aria-hidden="false"
      *ngIf="tooltip"
      matSuffix
      class="eyes-input-tooltip-anchor"
      [prosumerTooltip]="tooltip"
      >help</mat-icon
    >
  </mat-form-field>
</div>
<div
  *ngIf="
    inputNodeControl?.touched || inputNodeControl?.dirty || (submitted$ | async)
  "
>
  <mat-error
    *ngFor="let error of controls?.nodes?.errors | keyvalue"
    [innerHtml]="errorMessage[error.key]"
    class="filterchip-error"
  >
  </mat-error>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  <mat-option *ngFor="let node of nodeSelection$ | async" [value]="node">
    <div (click)="optionSelected($event, node)">
      {{ node.name }}
    </div>
  </mat-option>
</mat-autocomplete>
