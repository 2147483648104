<div
  [ngClass]="{
    'error-class':
      controls?.generics?.errors &&
      required &&
      (inputGenericControl?.touched ||
        inputGenericControl?.dirty ||
        (submitted$ | async))
  }"
>
  <mat-form-field
    appearance="outline"
    class="eyes-maximize-width filterchip hide-subscript"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid #chipList aria-label="Generics" [required]="required">
      <mat-chip-row
        [color]="'primary'"
        *ngFor="let generic of selectedGenerics"
        (removed)="remove(generic)"
        selected
      >
        {{ generic.name }}
        <mat-icon aria-hidden="false" matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input
        matInput
        #genericInput
        placeholder="{{ placeholder }}"
        [formControl]="inputGenericControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
    <mat-icon
      aria-hidden="false"
      *ngIf="tooltip"
      matSuffix
      class="eyes-input-tooltip-anchor"
      [prosumerTooltip]="tooltip"
      >help</mat-icon
    >
  </mat-form-field>
</div>
<div
  *ngIf="
    inputGenericControl?.touched ||
    inputGenericControl?.dirty ||
    (submitted$ | async)
  "
>
  <mat-error
    *ngFor="let error of getErrors(controls?.generics?.errors)"
    [innerHtml]="(this.errorMessage || {})[error]"
    class="filterchip-error"
  ></mat-error>
  <mat-error
    *ngIf="customErrorMessage$ | async"
    [innerHtml]="customErrorMessage$.getValue()"
    class="filterchip-error"
  ></mat-error>
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  <mat-option
    *ngFor="let generic of genericSelection$ | async"
    [value]="generic"
  >
    <div (click)="optionSelected($event, generic)">
      {{ generic.name }}
    </div>
  </mat-option>
</mat-autocomplete>
