/* eslint-disable @typescript-eslint/naming-convention */
import { Type } from '@angular/core';

export interface AnalyticsService {
  /* LATEST MODIFIED SCENARIO */
  trackLatestModifiedScenarioEdit(): void;
  trackLatestModifiedScenarioProject(): void;
  trackLatestModifiedScenarioCase(): void;
  trackLatestModifiedScenarioShowResults(): void;

  /* LATEST SCENARIO */
  trackLatestScenarioEdit(): void;
  trackLatestScenarioProject(): void;
  trackLatestScenarioCase(): void;
  trackLatestScenarioShowResults(): void;

  /* CONTINUE WORKING ON */
  trackContinueEdit(): void;
  trackContinueProject(): void;
  trackContinueCase(): void;

  /* SIDEBAR */
  trackSidebarProjects(): void;

  /* CASE PAGE */
  trackCasePageShowResults(): void;
  trackCasePageCompareScenario(): void;

  /* RESULT PAGE */
  trackResultPageSystemVisualisation(): void;
  trackResultPageEquipment(): void;
  trackResultPageTopology(): void;
  trackResultPageEnergyBalance(): void;
  trackResultPageCashFlow(): void;
  trackResultPageCO2Emissions(): void;
  trackResultPageDispatch(): void;
  trackResultPageFlows(): void;

  /* COMPARE SCENARIO */
  trackCompareScenarioEquipment(): void;
  trackCompareScenarioTopology(): void;
  trackCompareScenarioCashFlow(): void;
  trackCompareScenarioCO2Emissions(): void;
  trackCompareScenarioEnergyBalance(): void;
  trackCompareScenarioDispatch(): void;
}

export enum AnalyticsEvent {
  /* LATEST MODIFIED SCENARIO */
  LATEST_MODIFIED_SCENARIO_EDIT = 'LATEST_MODIFIED_SCENARIO_EDIT',
  LATEST_MODIFIED_SCENARIO_PROJECT = 'LATEST_MODIFIED_SCENARIO_PROJECT',
  LATEST_MODIFIED_SCENARIO_CASE = 'LATEST_MODIFIED_SCENARIO_CASE',
  LATEST_MODIFIED_SCENARIO_SHOW_RESULTS = 'LATEST_MODIFIED_SCENARIO_SHOW_RESULTS',
  /* LATEST SCENARIO */
  LATEST_SCENARIO_EDIT = 'LATEST_SCENARIO_EDIT',
  LATEST_SCENARIO_PROJECT = 'LATEST_SCENARIO_PROJECT',
  LATEST_SCENARIO_CASE = 'LATEST_SCENARIO_CASE',
  LATEST_SCENARIO_SHOW_RESULTS = 'LATEST_SCENARIO_SHOW_RESULTS',
  /* CONTINUE WORKING ON */
  CONTINUE_EDIT = 'CONTINUE_EDIT',
  CONTINUE_PROJECT = 'CONTINUE_PROJECT',
  CONTINUE_CASE = 'CONTINUE_CASE',

  /* SIDEBAR */
  SIDEBAR_PROJECT = 'SIDEBAR_PROJECT',

  /* CASE PAGE */
  CASE_PAGE_SHOW_RESULTS = 'CASE_PAGE_SHOW_RESULTS',
  CASE_PAGE_COMPARE_SCENARIO = 'CASE_PAGE_COMPARE_SCENARIO',

  /* RESULT PAGE */
  RESULT_PAGE_SYSTEM_VISUALISATION = 'RESULT_PAGE_SYSTEM_VISUALISATION',
  RESULT_PAGE_EQUIPMENT = 'RESULT_PAGE_EQUIPMENT',
  RESULT_PAGE_TOPOLOGY = 'RESULT_PAGE_TOPOLOGY',
  RESULT_PAGE_ENERGY_BALANCE = 'RESULT_PAGE_ENERGY_BALANCE',
  RESULT_PAGE_CASH_FLOW = 'RESULT_PAGE_CASH_FLOW',
  RESULT_PAGE_CO2_EMISSIONS = 'RESULT_PAGE_CO2_EMISSIONS',
  RESULT_PAGE_DISPATCH = 'RESULT_PAGE_DISPATCH',
  RESULT_PAGE_FLOWS = 'RESULT_PAGE_FLOWS',

  /* COMPARISON PAGE */
  COMPARISON_PAGE_EQUIPMENT = 'COMPARISON_PAGE_EQUIPMENT',
  COMPARISON_PAGE_TOPOLOGY = 'COMPARISON_PAGE_TOPOLOGY',
  COMPARISON_PAGE_CASH_FLOW = 'COMPARISON_PAGE_CASH_FLOW',
  COMPARISON_PAGE_CO2_EMISSIONS = 'COMPARISON_PAGE_CO2_EMISSIONS',
  COMPARISON_PAGE_ENERGY_BALANCE = 'COMPARISON_PAGE_ENERGY_BALANCE',
  COMPARISON_PAGE_DISPATCH = 'COMPARISON_PAGE_DISPATCH',
}

export interface AnalyticsConfig {
  readonly serviceClass: Type<AnalyticsService>;
  readonly amplitudeKey: string;
}
