import { NgModule } from '@angular/core';

import { CoreModule } from 'prosumer-core';

import { CaseRootModule } from './+case';
import { ProjectRootModule } from './+project';
import { RenewableProfileRootModule } from './+renewableprofile';
import { ScenarioRootModule } from './+scenario';

import { DrawerModule } from '@oculus/components/drawer';
import { FooterModule } from '@oculus/components/footer';
import { HeaderModule } from '@oculus/components/header';
import { TooltipModule } from 'prosumer-app/libs/eyes-shared';
import { SharedLandingPageResolver } from './+share/components/shared-landing-page';
import { AppComponent } from './app.component';
import { NotificationToolbarModule } from './shared/components/';
import { UserProfileModule } from './shared/modules/user-profile/user-profile.module';
@NgModule({
  imports: [
    CoreModule,
    /* Root Modules */
    ProjectRootModule,
    CaseRootModule,
    ScenarioRootModule,
    RenewableProfileRootModule,
    /* Oculus Modules */
    DrawerModule,
    FooterModule,
    HeaderModule,
    UserProfileModule,
    TooltipModule,
    NotificationToolbarModule,
  ],
  providers: [SharedLandingPageResolver],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
