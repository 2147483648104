<form [formGroup]="duplicateEntityForm" (ngSubmit)="onFinish(true)">
  <h3
    class="mat-headline-6"
    mat-dialog-title
    data-testid="duplicate-dialog-title"
  >
    Duplicate {{ data?.entityArchetype | titlecase }}
  </h3>
  <div fxLayout="column" fxLayoutGap="10px">
    <div
      *ngIf="data?.entityArchetype !== 'scenario'"
      class="mat-caption"
      style="font-style: italic"
      data-testid="duplicate-dialog-exclude-message"
    >
      {{ 'Generic.messages.duplicateUploadExclusion' | translate }}
    </div>
    <prosumer-input
      module="Scenario"
      formFieldName="duplicate.scenario"
      data-testid="duplicate-dialog-name-input"
      [label]="'New ' + (data?.entityArchetype | titlecase) + ' Name'"
      [control]="duplicateEntityForm?.controls?.duplicateName"
      [required]="true"
    >
    </prosumer-input>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="
        duplicateEntityForm?.invalid || (data.isBeingProcessed$ | async)
      "
      data-testid="duplicate-dialog-duplicate-button"
    >
      Duplicate
    </button>
    <button
      mat-flat-button
      type="button"
      color=""
      [disabled]="data.isBeingProcessed$ | async"
      (click)="onFinish(false)"
      data-testid="duplicate-dialog-cancel-button"
    >
      {{ 'Generic.labels.cancel' | translate }}
    </button>
  </div>
</form>
