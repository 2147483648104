<mat-progress-bar
  color="accent"
  mode="indeterminate"
  *ngIf="binaryLoading$ | async"
></mat-progress-bar>
<form [formGroup]="energyGridConnectionsEmissionsForm" (ngSubmit)="onConfirm()">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Commodity (Energy Grid Connections Emissions)
  </h2>
  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <!-- Name and Node-->
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Market Name -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnectionsEmissions.marketName'
              | translate
          "
          [label]="
            'Scenario.labels.energyGridConnectionsEmissions.marketName'
              | translate
          "
          [options]="marketOptions"
          [control]="energyGridConnectionsEmissionsForm?.controls?.marketName"
          [required]="true"
          [errorMessageMap]="errorMessages['marketName']"
          data-testid="energy-grid-connections-emission-form-dialog-market-name"
        >
        </prosumer-select>

        <!-- Node -->
        <prosumer-select
          *ngIf="isMultiNode; else single"
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnectionsEmissions.node'
              | translate
          "
          [label]="
            'Scenario.labels.energyGridConnectionsEmissions.node' | translate
          "
          [options]="nodeOptions"
          [control]="energyGridConnectionsEmissionsForm?.controls?.node"
          [required]="true"
          [errorMessageMap]="errorMessages['node']"
          data-testid="energy-grid-connections-emission-form-dialog-node"
        >
        </prosumer-select>
        <ng-template #single> </ng-template>

        <!-- Scope -->
        <prosumer-select
          fxFlex="33%"
          [placeholder]="
            'Scenario.placeholders.energyGridConnectionsEmissions.scope'
              | translate
          "
          [label]="
            'Scenario.labels.energyGridConnectionsEmissions.scope' | translate
          "
          [options]="scopeOptions"
          [control]="energyGridConnectionsEmissionsForm?.controls?.scope"
          [required]="true"
          [errorMessageMap]="errorMessages['scope']"
          data-testid="energy-grid-connections-emission-form-dialog-scope"
        >
        </prosumer-select>
      </div>

      <!-- [initValueCommodityOption]="updateValueFromEVSelection$ | async" -->
      <prosumer-tdb-emission-factors
        *ngIf="hasTdbAccess$ | async"
        [profileFilters]="data.tdbFilters"
        [yearsList]="[data.startYear, data.endYear]"
        (profileDataFetched)="onTDBDataFetch($event)"
        data-testid="egce-dialog-tdb-emission-factors-component"
      ></prosumer-tdb-emission-factors>

      <!-- Emissions Rate -->
      <div fxLayout="row" fxLayoutGap="10px">
        <prosumer-yearly-loads-interval
          formControlName="profiles"
          [required]="true"
          [startYear]="
            energyGridConnectionsEmissionsForm?.controls?.startYear.value
          "
          [endYear]="
            energyGridConnectionsEmissionsForm?.controls?.endYear.value
          "
          [defaultLocation]="'energyGridConnectionsEmissions.emissionsRate'"
          [useLibraryPanel]="true"
          [mode]="data.mode"
          [loading]="binaryLoading$ | async"
          [yearlyLoadMessages]="yearlyLoadMessage"
          [alwaysCustom]="true"
          [useLibraryPanel]="false"
          [yearlyLoadEnabled]="false"
          [yAxisLabel]="'Scenario.units.kgCO2Kwh' | translate"
          [allowNegativeInput]="true"
          (selectedTabChange)="onSelectInterval($event)"
        >
        </prosumer-yearly-loads-interval>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      *ngIf="!data?.isViewOnly"
      mat-flat-button
      type="submit"
      color="primary"
      [class.spinner]="saving$ | async"
      [class.unclickable]="saving$ | async"
      [disabled]="
        !isFormValid() || (binaryLoading$ | async) || (saving$ | async)
      "
      data-testid="energy-grid-connections-emissions-form-dialog-ok"
    >
      {{ data?.confirm || 'OK' }}
    </button>
    <button
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
      [disabled]="binaryLoading$ | async"
      data-testid="energy-grid-connections-emissions-form-dialog-cancel"
    >
      <span *ngIf="!data?.isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
