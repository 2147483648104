import { Inject, Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { AnalyticsConfig, AnalyticsEvent } from './analytics.model';
import { ANALYTICS_CONFIG } from './analytics.tokens';

@Injectable()
export class AnalyticsService {
  ampInstance: any;
  constructor(@Inject(ANALYTICS_CONFIG) config?: AnalyticsConfig) {
    this.ampInstance = amplitude.getInstance();
    this.ampInstance.init(config.amplitudeKey);
  }
  /* LATEST MODIFIED SCENARIO */
  trackLatestModifiedScenarioEdit() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_EDIT);
  }
  trackLatestModifiedScenarioProject() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_PROJECT);
  }
  trackLatestModifiedScenarioCase() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_CASE);
  }
  trackLatestModifiedScenarioShowResults() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_SHOW_RESULTS);
  }

  /* LATEST SCENARIO */
  trackLatestScenarioEdit() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_EDIT);
  }
  trackLatestScenarioProject() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_PROJECT);
  }
  trackLatestScenarioCase() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_CASE);
  }
  trackLatestScenarioShowResults() {
    this.ampInstance.logEvent(AnalyticsEvent.LATEST_SCENARIO_SHOW_RESULTS);
  }

  /* CONTINUE WORKING ON */
  trackContinueEdit() {
    this.ampInstance.logEvent(AnalyticsEvent.CONTINUE_EDIT);
  }
  trackContinueProject() {
    this.ampInstance.logEvent(AnalyticsEvent.CONTINUE_PROJECT);
  }
  trackContinueCase() {
    this.ampInstance.logEvent(AnalyticsEvent.CONTINUE_CASE);
  }

  /* SIDEBAR */
  trackSidebarProjects() {
    this.ampInstance.logEvent(AnalyticsEvent.SIDEBAR_PROJECT);
  }

  /* CASE PAGE */
  trackCasePageShowResults() {
    this.ampInstance.logEvent(AnalyticsEvent.CASE_PAGE_SHOW_RESULTS);
  }
  trackCasePageCompareScenario() {
    this.ampInstance.logEvent(AnalyticsEvent.CASE_PAGE_COMPARE_SCENARIO);
  }

  /* RESULT PAGE */
  trackResultPageSystemVisualisation() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_SYSTEM_VISUALISATION);
  }
  trackResultPageEquipment() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_EQUIPMENT);
  }
  trackResultPageTopology() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_TOPOLOGY);
  }
  trackResultPageEnergyBalance() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_ENERGY_BALANCE);
  }
  trackResultPageCashFlow() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_CASH_FLOW);
  }
  trackResultPageCO2Emissions() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_CO2_EMISSIONS);
  }
  trackResultPageDispatch() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_DISPATCH);
  }
  trackResultPageFlows() {
    this.ampInstance.logEvent(AnalyticsEvent.RESULT_PAGE_FLOWS);
  }

  /* COMPARE PAGE */
  trackCompareScenarioEquipment() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_EQUIPMENT);
  }
  trackCompareScenarioTopology() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_TOPOLOGY);
  }
  trackCompareScenarioCashFlow() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_CASH_FLOW);
  }
  trackCompareScenarioCO2Emissions() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_CO2_EMISSIONS);
  }
  trackCompareScenarioEnergyBalance() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_ENERGY_BALANCE);
  }
  trackCompareScenarioDispatch() {
    this.ampInstance.logEvent(AnalyticsEvent.COMPARISON_PAGE_DISPATCH);
  }
}
