<div fxLayout="column" fxLayoutAlign="start stretch">
  <mat-form-field appearance="outline" class="eyes-spacer-half">
    <mat-label>Equipment</mat-label>
    <mat-select [formControl]="equipmentControl">
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <prosumer-stacked-bar-chartjs
    [id]="randomChartID"
    [data]="chartData$ | async"
    [colors]="colors$ | async"
    resultsName="compare_weekly_storage_dispatch"
  ></prosumer-stacked-bar-chartjs>
</div>
