import { Coerce } from 'prosumer-app/core/utils';
import { YearlyValues } from 'prosumer-app/shared/models';

import { Injectable } from '@angular/core';

import {
  TDB_COMMODITY_PRICES_ADDITIONAL_COSTS_PERCENTAGE_TYPE_KEYS_LIST,
  TDBCommodityPricesAdditionalCosts,
} from '../../models';

const DEFAULT_VALUE = 0;

@Injectable({ providedIn: 'root' })
export class TdbAdditionalCostsHelperService {
  injectAdditionalCostsForYear(
    criteria: Record<string, string | number>,
    additionalCosts: Record<string, YearlyValues>,
  ): void {
    const year = criteria.year ? criteria.year : criteria.startYear;
    // criteria[TDBCommodityPricesAdditionalCosts.indexation] =
    //   additionalCosts[TDBCommodityPricesAdditionalCosts.indexation][year];
    // criteria[TDBCommodityPricesAdditionalCosts.taxes] =
    //   additionalCosts[TDBCommodityPricesAdditionalCosts.taxes][year];
    // criteria[TDBCommodityPricesAdditionalCosts.transpoAndDistrib] =
    //   additionalCosts[TDBCommodityPricesAdditionalCosts.transpoAndDistrib][
    //     year
    //   ];
    // criteria[TDBCommodityPricesAdditionalCosts.margin] =
    //   additionalCosts[TDBCommodityPricesAdditionalCosts.margin][year];
    /** TODO: proposed code refactor to be more dynamic and deterministic
     *  -> would work whether additionalCost has been passed or not
     *  -> dynamic and deterministic alternative for line 19 to 28
     *  -> this would make test passing (without any changes on test file) */
    criteria = { ...criteria, ...this.getCostsByYear(additionalCosts, year) };
  }

  initYearlyValue(
    yearList: [number, number],
    defaultV = DEFAULT_VALUE,
  ): object {
    const e = {};
    for (let y = yearList[0]; y <= yearList[1]; y++) {
      e[y] = defaultV;
    }
    return e;
  }

  formatPercentageToFloat(d: Record<string, YearlyValues>) {
    TDB_COMMODITY_PRICES_ADDITIONAL_COSTS_PERCENTAGE_TYPE_KEYS_LIST.forEach(
      (name) => (d[name] = this.formatYearlyValuePercentageToFloat(d[name])),
    );
    return d;
  }

  /** TODO: proposed code refactor to be more dynamic and deterministic
   *  -> this would make test passing (without any changes on test file) */
  private getCostsByYear(
    additionalCosts: Record<string, YearlyValues>,
    year: string | number,
  ): Record<string, string | number> {
    additionalCosts = Coerce.toObject(additionalCosts, {});
    return Object.values(TDBCommodityPricesAdditionalCosts).reduce(
      (criteria, key) => ({
        ...criteria,
        key: Coerce.toObject(additionalCosts[key])[year],
      }),
      {},
    );
  }

  private formatYearlyValuePercentageToFloat(data: YearlyValues): YearlyValues {
    const e = {};
    for (const key in data) {
      e[key] = String(this.percentageToFloat(data[key]));
    }
    return e;
  }

  private percentageToFloat(v: string | number) {
    return Number(v) / 100;
  }
}
