import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Scenario } from 'prosumer-app/+scenario/models';
import { ScenarioFacadeExtensions } from './../../state/scenario-exts.service';

@Component({
  selector: 'prosumer-scenario-simulation',
  templateUrl: './scenario-simulation.component.html',
  styleUrls: ['./scenario-simulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioSimulationComponent {
  readonly runningIcon = '/assets/icons/running.png';
  constructor(
    private scenarios: ScenarioFacadeExtensions,
    private router: Router,
  ) {}

  onOK(): void {
    this.router.navigate(this.urlFragmentsToSelectedCase());
  }

  private urlFragmentsToSelectedCase(): string[] {
    return [
      'projects',
      this.getSelectedScenario().projectId,
      'cases',
      this.getSelectedScenario().caseId,
    ];
  }

  private getSelectedScenario(): Scenario {
    return this.scenarios.getSelected();
  }
}
