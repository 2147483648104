<form [formGroup]="nettingForm">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} Netting
  </h2>
  <div class="mat-body" mat-dialog-content>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Scenario.labels.general.parameters' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Netting Name -->
          <prosumer-input
            module="Scenario"
            formFieldName="netting.name"
            [attr.data-testid]="'netting-form-name'"
            [inputTooltip]="'wizard_netting.wizard_netting_name' | translate"
            [control]="nameCtrl"
            [required]="true"
          >
          </prosumer-input>
          <!-- Time Partition -->
          <prosumer-select
            [placeholder]="
              'Scenario.placeholders.netting.timePartition' | translate
            "
            [label]="'Scenario.labels.netting.timePartition' | translate"
            [options]="timePartitionOptions"
            [control]="timePartitionCtrl"
            [required]="true"
            [errorMessageMap]="errorMessages?.timePartition"
            [tooltip]="
              'wizard_netting.wizard_netting_time_partition' | translate
            "
            data-testid="netting-form-time-partition"
          >
          </prosumer-select>
          <!-- Energy Vector -->
          <prosumer-select
            [placeholder]="'Scenario.placeholders.energyVector' | translate"
            [label]="'Scenario.labels.energyVector' | translate"
            [options]="energyVectorOptions"
            [control]="energyVectorCtrl"
            [required]="true"
            [errorMessageMap]="errorMessages?.energyVector"
            [tooltip]="
              'wizard_netting.wizard_netting_energy_vector' | translate
            "
            data-testid="netting-form-energy-vector"
          >
          </prosumer-select>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <!-- Node Input -->
        <prosumer-node-filterchip-component
          *ngIf="isMultiNode; else single"
          [errorMessage]="errorMessages?.netting.node"
          [setSubmitted]="submitted$ | async"
          formControlName="nodes"
          [nodeOptions]="nodeOptions"
          [tooltip]="'wizard_netting.wizard_netting_nodes' | translate"
          data-testid="netting-node"
        >
        </prosumer-node-filterchip-component>
        <ng-template #single>
          <div fxFlex="40%"></div>
        </ng-template>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header collapsedHeight="35px" expandedHeight="35px">
        <mat-panel-title>
          <h3 class="mat-headline-6">
            {{ 'Scenario.labels.netting.assetsBehindTheMeter' | translate }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="10px">
        <!-- Equipment Input -->
        <prosumer-generic-filterchip-component
          fxFlex="100%"
          [label]="'Scenario.labels.netting.equipment' | translate"
          [placeholder]="'Scenario.placeholders.netting.equipment' | translate"
          [tooltip]="'wizard_netting.wizard_netting_equipments' | translate"
          [genericOptions]="equipmentsOptions"
          formControlName="equipments"
          [errorMessage]="errorMessages?.netting.equipment"
          [setSubmitted]="submitted$ | async"
          data-testid="equipments"
          [required]="true"
        >
        </prosumer-generic-filterchip-component>

        <!-- Loads Input -->
        <prosumer-generic-filterchip-component
          fxFlex="100%"
          label="{{ 'Scenario.labels.netting.load' | translate }}"
          placeholder="{{ 'Scenario.placeholders.netting.load' | translate }}"
          [tooltip]="'wizard_netting.wizard_netting_loads' | translate"
          [genericOptions]="loadsOptions"
          formControlName="loads"
          [enableSelectAll]="false"
          data-testid="loads"
          [required]="false"
        >
        </prosumer-generic-filterchip-component>
      </div>
    </mat-expansion-panel>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="!isViewOnly"
      [id]="data.id"
      [disabled]="isFormPristine"
      [data]="formatFormValues()"
      [mode]="data.mode"
      [valid]="isFormValid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    >
    </prosumer-entity-upsert-button>

    <button
      data-testid="cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
