import { MarkdownModule } from 'ngx-markdown';
import {
  TRAINING_URL_REDIRECT_PROVIDER,
  UPDATES_URL_REDIRECT_PROVIDER,
} from 'prosumer-app/app.token';
import {
  API_CONFIG,
  AUTH_CONFIG,
  ENV_CONFIG,
  ErrorService,
  EyesCoreModule,
  FAQ_URL_REDIRECT_PROVIDER,
  FEATURES_CONFIG,
  HOME_URL_REDIRECT_PROVIDER,
  HTTP_CONFIG,
  LOGIN_URL_REDIRECT_PROVIDER,
  PUBSUB_CONFIG,
  SUPPORT_CONFIG,
  UserAPIService,
  VERSION_CONFIG,
  WEBSOCKET_CONFIG,
  clearStatesReducer,
} from 'prosumer-app/libs/eyes-core';
import {
  EyesSharedModule,
  NgxModuleModule,
  SHARED_API_TOKEN,
  SHARED_SUPPORT_TOKEN,
  SHARED_VERSION_TOKEN,
} from 'prosumer-app/libs/eyes-shared';
import {
  AuthService,
  ProsumerRoutePathService,
  UserService,
} from 'prosumer-core-services';
import { environment } from 'prosumer-env';
import {
  AnalyticsModule,
  AnalyticsService,
  CUSTOM_FLUID_COLOR_PALETTE,
  GENERATE_COLOR_SCHEME,
  PREDEFINED_FLUID_LABEL_CONFIG,
  SYS_VIS_FLUID_CONFIG,
  SharedModule,
} from 'prosumer-shared';

import { LowerCasePipe } from '@angular/common';
import { HttpBackend, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AuthModule,
  AuthService as OculusAuthService,
} from '@oculus/auth/amplify';

import { AppRoutingModule, FEATURES } from '../app-routing.module';
import {
  CUSTOM_EV_COLOR_PALETTE,
  EV_FLUID_CONFIG_MAP,
  PREDEFINED_ENERGY_VECTOR_MAP_LABEL,
  SUPPORT,
  generateShadedScheme,
} from '../app.references';
import {
  LibraryStoreModule,
  MessageStoreModule,
  ResultStoreModule,
  ScenarioBinStoreModule,
  ScenarioStoreModule,
} from '../stores';
import { ResourceCheckerModule } from './interceptors/resource-checker/resource-checker.module';
import { BillingModule } from './modules';
import { SessionExpiredMessageComponent } from './services/session-expired-handle/components/session-expired-message';
import {
  redirectFAQ,
  redirectHome,
  redirectLogin,
  redirectTraining,
  redirectUpdates,
} from './utils';
import { HttpLoaderFactory } from './services/translate-i18n';

@NgModule({
  imports: [
    /* Angular */
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    /* Service Worker */
    ServiceWorkerModule.register(`ngsw-worker.js`, {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:20000',
    }),
    /* Dev Tools */
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({ connectInZone: true }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    /* Third Party */
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      useDefaultLang: true,
    }),
    NgxModuleModule,
    /* Eyes Libraries */
    EyesSharedModule.forRoot(),
    EyesCoreModule,
    /* Prosumer Libraries */
    SharedModule,
    /* Routing */
    AppRoutingModule,
    /* Old Stores - Soon to be deprecated */
    ScenarioStoreModule.forRoot(),
    ScenarioBinStoreModule.forRoot(),
    ResultStoreModule.forRoot(),
    MessageStoreModule.forRoot(),
    LibraryStoreModule.forRoot(),
    /* Core Modules */
    BillingModule,
    /* Markdown processor */
    MarkdownModule.forRoot(),
    /* Oculus Libraries */
    AuthModule.forRoot(environment.cognito.Auth),
    AnalyticsModule.forRoot({
      serviceClass: AnalyticsService,
      amplitudeKey: environment.amplitudeAPIKey,
    }),
    ResourceCheckerModule.forRoot(),
  ],
  declarations: [SessionExpiredMessageComponent],
  providers: [
    /* Error */
    {
      provide: ErrorHandler,
      useClass: environment.production ? ErrorService : ErrorHandler,
    },
    /* NgRx */
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useValue: environment.production
        ? [clearStatesReducer]
        : [clearStatesReducer],
    },
    /* Material Dates */
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    /* Environment */
    { provide: ENV_CONFIG, useValue: environment },
    { provide: HTTP_CONFIG, useValue: environment.http },
    { provide: AUTH_CONFIG, useValue: environment.cognito.Auth },
    { provide: API_CONFIG, useValue: environment.api },
    { provide: PUBSUB_CONFIG, useValue: environment.pubSub },
    { provide: VERSION_CONFIG, useValue: environment.version },
    { provide: WEBSOCKET_CONFIG, useValue: environment.websocket },
    { provide: FEATURES_CONFIG, useValue: FEATURES },
    /* Redirection */
    { provide: HOME_URL_REDIRECT_PROVIDER, useValue: redirectHome },
    { provide: LOGIN_URL_REDIRECT_PROVIDER, useValue: redirectLogin },
    { provide: FAQ_URL_REDIRECT_PROVIDER, useValue: redirectFAQ },
    { provide: TRAINING_URL_REDIRECT_PROVIDER, useValue: redirectTraining },
    { provide: UPDATES_URL_REDIRECT_PROVIDER, useValue: redirectUpdates },
    /* Color Scheme */
    { provide: GENERATE_COLOR_SCHEME, useValue: generateShadedScheme },
    /* System Visualization */
    { provide: SYS_VIS_FLUID_CONFIG, useValue: EV_FLUID_CONFIG_MAP },
    {
      provide: PREDEFINED_FLUID_LABEL_CONFIG,
      useValue: PREDEFINED_ENERGY_VECTOR_MAP_LABEL,
    },
    { provide: CUSTOM_FLUID_COLOR_PALETTE, useValue: CUSTOM_EV_COLOR_PALETTE },
    /* Prosumer Specific Services */
    ProsumerRoutePathService,
    /* Pipe as a Service*/
    LowerCasePipe,

    // support module
    { provide: SHARED_API_TOKEN, useValue: environment.api },
    { provide: SHARED_VERSION_TOKEN, useValue: environment.version },
    { provide: SHARED_SUPPORT_TOKEN, useValue: SUPPORT },
    { provide: SUPPORT_CONFIG, useValue: SUPPORT },
    { provide: UserAPIService, useClass: UserService },
    { provide: OculusAuthService, useClass: AuthService },
  ],
  exports: [EyesSharedModule],
  // entryComponents: [BaseDialogComponent],
})
export class CoreModule {}
