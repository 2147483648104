import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';

export const scenarioUploadPageTokens = {
  gamsTableColumnDef: {
    name: {
      name: 'File Name',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      flex: '20%',
    },
  } as ColumnDefinition,
  scenarioTableColumnDef: {
    scenarioName: {
      name: 'Name',
    },
    fileName: {
      name: 'File',
    },
    statusIcon: {
      name: 'Status',
      type: 'statusIcon',
      flex: '70px',
      alignment: 'center',
    },
    actions: {
      name: 'Action',
      type: 'action',
      flex: '75px',
    },
  } as ColumnDefinition,
};
