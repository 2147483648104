import { LoggerService } from 'prosumer-app/libs/eyes-core';
import { BaseComponent, ColumnDefinition } from 'prosumer-app/libs/eyes-shared';
import { startWith, take } from 'rxjs/operators';

import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ScenarioFacadeService } from '../../state';
import { scenarioUploadPageTokens } from './scenario-upload-page.token';

@Component({
  selector: 'prosumer-scenario-upload-page',
  templateUrl: './scenario-upload-page.component.html',
  styleUrls: ['./scenario-upload-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioUploadPageComponent
  extends BaseComponent
  implements OnInit
{
  projectId: string;
  caseId: string;
  params$ = this._route.params.pipe(take(1), this.takeUntilShare());
  uploading$ = this.scenarioFacade.uploading$.pipe(
    startWith(false),
    this.takeUntilShare(),
  );
  private readonly gamsFiles = signal<File[]>([]);

  constructor(
    private _logger: LoggerService,
    private _route: ActivatedRoute,
    private _location: Location,
    public scenarioFacade: ScenarioFacadeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.params$.subscribe((params) => {
      if (params) {
        this.projectId = params.projectId;
        this.caseId = params.caseId;
      }
    });
  }

  gamsFilesChanged(event: File[]) {
    this.gamsFiles.set(event);
  }

  onCancel(): void {
    this._location.back();
  }

  onCreate(data: any[]): void {
    this._logger.info(data);
    data.forEach((d) => {
      this.scenarioFacade.upload(
        {
          ...d,
          projectId: this.projectId,
          caseId: this.caseId,
        },
        d.fileInput,
        this.gamsFiles(),
      );
    });
  }

  get gamsColumnDef(): ColumnDefinition {
    return scenarioUploadPageTokens.gamsTableColumnDef;
  }

  get scenarioInputColumnDef(): ColumnDefinition {
    return scenarioUploadPageTokens.scenarioTableColumnDef;
  }
}
