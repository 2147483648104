<div
  class="chart-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  [@fadeIn]
>
  <ngx-charts-area-chart-stacked
    id="charts-area-chart"
    [results]="filteredData$ | async"
    (select)="onSelect($event)"
    [view]="view"
    [showXAxisLabel]="true"
    [xAxis]="true"
    [xAxisLabel]="xAxisLabel"
    [xAxisTickFormatting]="xAxisFormat"
    [showYAxisLabel]="true"
    [yAxis]="true"
    [yAxisLabel]="yAxisLabel"
    [yAxisTickFormatting]="yAxisFormat"
    [legend]="showLegend"
    [legendPosition]="'right'"
    [timeline]="false"
    [showGridLines]="true"
    [scheme]="scheme"
  >
    <ng-template #tooltipTemplate let-model="model">
      <prosumer-chart-tooltip
        [currentTooltipData]="model[0]"
        [tooltipDataList]="
          seriesDataList | chartTooltipDataList: model : scheme
        "
        [unit]="yAxisLabel"
        [titleFormat]="tooltipTitleFormat"
      >
      </prosumer-chart-tooltip>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
      <prosumer-chart-tooltip
        [currentTooltipData]="model[0]"
        [tooltipDataList]="model"
        [unit]="yAxisLabel"
        [titleFormat]="seriesTooltipTitleFormat"
      >
      </prosumer-chart-tooltip>
    </ng-template>
  </ngx-charts-area-chart-stacked>
  <div *ngIf="hasRangeSlider" class="slider-container">
    <ngx-slider
      [options]="sliderOptions"
      [manualRefresh]="manualRefresh"
      [value]="from"
      [highValue]="to"
      (valueChange)="onFromChange($event)"
      (highValueChange)="onToChange($event)"
    >
    </ngx-slider>
  </div>
</div>
<ng-template #placeholder>
  <div fxLayout="column" fxFlex fxFill fxLayoutAlign="center center">
    <mat-icon aria-hidden="false" class="large" color="primary">
      insert_chart_outlined
    </mat-icon>
    <h2>{{ 'Generic.messages.chartEmpty' | translate }}</h2>
  </div>
</ng-template>
