import { Coerce } from 'prosumer-app/core/utils';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const INVERSE_VALID_NAME_PATTERN = /[^a-zA-Z0-9\s\-_]+/;
const MAXIMUM_NAME_LENGTH = 63;

export class NameValidator {
  static validate(value: string, regex: RegExp): ValidationErrors | null {
    const errorKeys = [
      NameValidator.hasInvalidCharacterLength(value),
      NameValidator.hasInvalidCharacter(value, regex),
      NameValidator.hasEmptySpaceValue(value),
    ].filter((key) => key !== null);
    return errorKeys.reduce<ValidationErrors | null>(
      (errors, key) => ({ [key]: true }),
      null,
    );
  }

  static validWithCore(regex = INVERSE_VALID_NAME_PATTERN): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = Coerce.toObject(control).value;
      if ([!!control, !!value].every(Boolean)) {
        return NameValidator.validate(value, regex);
      }

      return null;
    };
  }

  static isValidWithCore(value: string): string[] {
    // return !value.match(INVERSE_VALID_NAME_PATTERN) && !!value.trim();
    return Object.keys(
      Coerce.toObject(
        NameValidator.validate(value, INVERSE_VALID_NAME_PATTERN),
      ),
    );
  }

  private static hasEmptySpaceValue(value: string): string | null {
    if (!!!value.trim()) {
      return 'whiteSpaces';
    }
    return null;
  }
  private static hasInvalidCharacter(
    value: string,
    regex: RegExp,
  ): string | null {
    if (value.match(regex)) {
      return 'invalidCharacter';
    }
    return null;
  }
  private static hasInvalidCharacterLength(value: string): string | null {
    if (value.length > MAXIMUM_NAME_LENGTH) {
      return 'maxLength';
    }
    return null;
  }
}
