<div fxLayout="column" fxLayoutGap="24px">
  <h2 class="mat-headline-6" mat-dialog-title data-testid="dialogTitle">
    {{ title }}
  </h2>
  <ng-content></ng-content>
  <div fxLayout="row" fxLayoutGap="8px">
    <button
      mat-flat-button
      color="primary"
      data-testid="okButton"
      [disabled]="disabled || loading"
      [class.spinner]="loading"
      (click)="onOk()"
    >
      {{ okLabel | uppercase }}
    </button>
    <button
      mat-flat-button
      color=""
      data-testid="cancelButton"
      [disabled]="loading"
      (click)="onCancel()"
    >
      {{ 'Generic.labels.cancel' | translate | uppercase }}
    </button>
  </div>
</div>
