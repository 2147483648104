<mat-progress-bar
  *ngIf="loading$ | async"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>
<!-- <prosumer-page> -->
<div class="support-page-container">
  <h2 class="mat-headline-6">{{ 'Support.title' | translate }}</h2>
  <form
    fxLayout="column"
    fxLayoutGap="20px"
    [formGroup]="supportForm"
    #formDirective="ngForm"
    (ngSubmit)="onSubmit(formDirective)"
  >
    <div class="support-container" fxLayout="column" fxLayoutGap="10px">
      <div
        *ngIf="success$ | async"
        class="banner-class mat-elevation-z2"
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <div fxLayoutAlign="space-between">
          <mat-label> Your ticket {{ ticket }} has been submitted.</mat-label>
          <a
            mat-button
            type="button"
            color="primary"
            (click)="createNewTicket()"
          >
            <b>CREATE AGAIN</b>
          </a>
        </div>
      </div>

      <!-- summary -->
      <mat-form-field fxFlex="40%" appearance="outline">
        <mat-label>{{ 'Support.field.summary' | translate }}</mat-label>
        <input
          required
          matInput
          formControlName="summary"
          [placeholder]="'Support.placeholder.summary' | translate"
        />
      </mat-form-field>

      <!-- description -->
      <mat-form-field fxFlex="40%" appearance="outline">
        <mat-label>{{ 'Support.field.description' | translate }}</mat-label>
        <textarea
          matInput
          [required]="true"
          [placeholder]="'Support.placeholder.description' | translate"
          formControlName="description"
          rows="10"
        ></textarea>
        <mat-error
          *ngIf="supportForm?.controls?.description?.errors?.required"
          [innerHtml]="errorMessages['description']?.required"
        ></mat-error>
        <!-- <mat-error
          *ngFor="
            let error of supportForm?.controls?.description?.errors | keyvalue
          "
          [ngSwitch]="error.key"
        >
          <div
            *ngSwitchCase="'required'"
            [innerHtml]="errorMessages['description']?.required"
          ></div>
        </mat-error> -->
      </mat-form-field>

      <!-- type of request -->
      <mat-form-field fxFlex="40%" appearance="outline">
        <mat-label>{{ 'Support.field.typeOfRequest' | translate }}</mat-label>
        <mat-select
          required
          formControlName="typeOfRequest"
          [placeholder]="'Support.placeholder.typeOfRequest' | translate"
        >
          <mat-option *ngFor="let type of typeOfRequests" [value]="type.value">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- optional url -->
      <mat-form-field fxFlex="40%" appearance="outline">
        <mat-label>{{ 'Support.field.currentUrl' | translate }}</mat-label>
        <input
          matInput
          formControlName="currentUrl"
          [placeholder]="'Support.placeholder.currentUrl' | translate"
        />
      </mat-form-field>
    </div>

    <div
      [@fadeIn]
      fxLayout="row"
      fxLayoutGap="10px"
      class="eyes-spacer-top-half"
    >
      <button
        mat-flat-button
        color="primary"
        class="eyes-button"
        [disabled]="
          supportForm?.invalid ||
          supportForm?.pristine ||
          (success$ | async) ||
          (loading$ | async)
        "
      >
        {{ 'Submit' }}
      </button>
    </div>
  </form>
</div>
<!-- </prosumer-page> -->
