<form [formGroup]="form">
  <h2 class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }}
    {{
      'Scenario.dialog.frequencyControl.spinningReserves.equipment.title'
        | translate
    }}
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Equipment -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="equipment-equipment-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.equipment.labels.equipment'
              | translate
          }}</mat-label>

          <mat-select
            data-testid="equipment-form-equipment-option"
            formControlName="equipmentId"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.placeholders.equipment'
                | translate
            "
          >
            <mat-option
              *ngFor="let equipment of equipmentOptions"
              [value]="equipment.id"
            >
              {{ equipment.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="equipment-form-equipment-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.equipment'"
          >
            help
          </mat-icon>

          <mat-error
            data-testid="equipment-form-equipment-error"
            *ngFor="let error of equipmentIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.equipment.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'dataExist'"
              [style.line-height.px]="12"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.equipment.dataExist'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Energy Vector -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="equipment-form-ev-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.equipment.labels.energyVector'
              | translate
          }}</mat-label>

          <mat-select
            data-testid="equipment-form-ev-option"
            formControlName="energyVectorId"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.placeholders.energyVector'
                | translate
            "
          >
            <mat-option
              *ngFor="let energyVector of energyVectorOptions"
              [value]="energyVector.value"
            >
              {{ energyVector.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="equipment-form-ev-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.reserve_energy_vector'"
          >
            help
          </mat-icon>
          <mat-error
            data-testid="equipment-form-ev-error"
            *ngFor="let error of energyVectorIdControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.energyVector.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Direction -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="equipment-form-direction-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.equipment.labels.direction'
              | translate
          }}</mat-label>

          <mat-select
            data-testid="equipment-form-direction-option"
            formControlName="direction"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.placeholders.direction'
                | translate
            "
          >
            <mat-option
              *ngFor="let direction of sortedDirections"
              [value]="direction.key"
            >
              {{ direction.value }}
            </mat-option>
          </mat-select>
          <mat-icon
            aria-hidden="false"
            data-testid="equipment-form-direction-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="'wizard_frequency_control.direction'"
          >
            help
          </mat-icon>
          <mat-error
            data-testid="equipment-form-direction-error"
            *ngFor="let error of directionControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.direction.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around stretch"
      >
        <!-- Participation -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="equipment-form-participation-title">{{
            'Scenario.dialog.frequencyControl.spinningReserves.equipment.labels.participation'
              | translate
          }}</mat-label>

          <mat-select
            data-testid="equipment-form-participation-option"
            formControlName="participation"
            required
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.placeholders.participation'
                | translate
            "
          >
            <mat-option
              *ngFor="let participation of sortedParticipations"
              [value]="participation.key | coerceBoolean"
            >
              {{ participation.value }}
            </mat-option>
          </mat-select>

          <mat-icon
            aria-hidden="false"
            data-testid="equipment-participation-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_frequency_control.equipment_participation' | translate
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="equipment-participation-error"
            *ngFor="let error of participationControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.participation.required'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>

        <!-- Requirement -->
        <mat-form-field
          class="eyes-maximize-width"
          appearance="outline"
          [@fadeIn]
        >
          <mat-label data-testid="equpment-requirement-title">
            {{
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.labels.requirement'
                | translate
            }}
          </mat-label>

          <input
            data-testid="equipment-requirement-input"
            matInput
            formControlName="requirement"
            required
            type="number"
            [placeholder]="
              'Scenario.dialog.frequencyControl.spinningReserves.equipment.placeholders.requirement'
                | translate
            "
          />

          <mat-icon
            aria-hidden="false"
            data-testid="equpment-requirement-tooltip"
            class="eyes-input-tooltip-anchor"
            matSuffix
            [prosumerTooltip]="
              'wizard_frequency_control.equipment_requirement' | translate
            "
          >
            help
          </mat-icon>

          <mat-error
            data-testid="equipment-requirement-error"
            *ngFor="let error of requirementControl?.errors | keyvalue"
            [ngSwitch]="error.key"
          >
            <div
              *ngSwitchCase="'required'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.requirement.required'
                  | translate
              "
            ></div>
            <div
              *ngSwitchCase="'min'"
              [innerHtml]="
                'Scenario.dialog.frequencyControl.spinningReserves.equipment.errors.requirement.min'
                  | translate
              "
            ></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutGap="8px">
    <prosumer-entity-upsert-button
      *ngIf="data.mode !== 'view'"
      [id]="data.currentReserveEquipment?.id"
      [disabled]="form.pristine"
      [data]="form.value"
      [mode]="data.mode"
      [valid]="form.valid"
      (ok)="onSaveSuccess()"
      (attempt)="onSaveAttempt()"
    ></prosumer-entity-upsert-button>

    <button
      mat-flat-button
      type="button"
      (click)="onClose()"
      [attr.data-testid]="'equipment-form-cancel'"
    >
      <span
        [attr.data-testid]="'equipment-form-cancel-label'"
        *ngIf="data?.mode !== 'view'; else closeButton"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </span>
      <ng-template #closeButton>
        <span [attr.data-testid]="'equipment-form-close-label'">{{
          'Generic.labels.close' | translate
        }}</span>
      </ng-template>
    </button>
  </div>
</form>
