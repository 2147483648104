<mat-progress-bar
  color="accent"
  mode="indeterminate"
  [attr.data-testid]="'loads-form-progress'"
  *ngIf="
    (loading$ | async) || (binaryLoading$ | async) || (libraryLoading$ | async)
  "
></mat-progress-bar>

<form [formGroup]="loadForm" (ngSubmit)="onConfirm()">
  <h2 data-testid="load-form-title" class="mat-headline-6" mat-dialog-title>
    {{ data?.mode | titlecase }} {{ 'Scenario.labels.load.name' | translate }}
    <prosumer-tooltip-anchor
      data-testid="load-form-tooltip"
      [message]="'wizard_loads.wizard_loads_title'"
    ></prosumer-tooltip-anchor>
  </h2>

  <div class="mat-body" mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutGap="10px">
          <prosumer-input
            fxFlex="25%"
            module="Scenario"
            formFieldName="load.name"
            placeholder="Input a load name"
            [control]="loadForm?.controls?.name"
            [attr.data-testid]="'load-form-name'"
            [required]="true"
          >
          </prosumer-input>
          <prosumer-select
            fxFlex="25%"
            [placeholder]="'Scenario.placeholders.energyVector' | translate"
            [label]="'Scenario.labels.energyVector' | translate"
            [options]="energyVectorOptions$ | async"
            [control]="loadForm?.controls?.energyVector"
            [required]="true"
            [errorMessageMap]="errorMessages?.energyVector"
            [tooltip]="'wizard_loads.wizard_loards_energy_vector'"
            data-testid="load-form-energy-vector"
          >
          </prosumer-select>

          <!-- Scenario Variation -->
          <prosumer-select
            fxFlex="25%"
            *ngIf="(scenarioVariationOptions$ | async).length > 1"
            [placeholder]="
              'Scenario.placeholders.load.scenarioVariation' | translate
            "
            [label]="'Scenario.labels.load.scenarioVariation' | translate"
            [options]="scenarioVariationOptions$ | async"
            [control]="loadForm?.controls?.scenarioVariation"
            [errorMessageMap]="errorMessages?.load"
            data-testid="loads-variation"
          ></prosumer-select>
        </div>

        <prosumer-node-filterchip-component
          fxFlex="30%"
          *ngIf="isMultiNode; else singleNodePadding"
          formControlName="nodes"
          [nodeOptions]="nodeOptions$ | async"
          [errorMessage]="errorMessages?.nodes.name"
          [tooltip]="'wizard_loads.wizard_loads_node'"
          [attr.data-testid]="'load-form-node-options'"
        ></prosumer-node-filterchip-component>

        <ng-template #singleNodePadding>
          <div fxFlex="30%"></div>
        </ng-template>
      </div>

      <prosumer-yearly-loads-interval
        formControlName="profiles"
        [required]="true"
        [startYear]="startYear"
        [endYear]="endYear"
        [libraries]="libraries"
        [mode]="data?.mode"
        [loading]="(binaryLoading$ | async) || (libraryLoading$ | async)"
        (selectedTabChange)="onSelectInterval($event)"
        (selectedLibraryChange)="onSelectLibrary($event)"
        (libraryFiltersChange)="onApplyLibraryFilters($event)"
        [attr.data-testid]="'load-form-yearly-loads-interval'"
      >
      </prosumer-yearly-loads-interval>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button
      *ngIf="!isViewOnly"
      data-testid="load-form-ok-button"
      mat-flat-button
      type="submit"
      color="primary"
      [disabled]="
        !hasValidChanges() ||
        (binaryLoading$ | async) ||
        (libraryLoading$ | async) ||
        (loading$ | async)
      "
    >
      {{ 'Generic.labels.ok' | translate }}
    </button>
    <button
      data-testid="load-form-cancel-button"
      mat-flat-button
      type="button"
      color=""
      (click)="onClose()"
      [disabled]="binaryLoading$ | async"
    >
      <span *ngIf="!isViewOnly; else closeButton">{{
        'Generic.labels.cancel' | translate
      }}</span>
      <ng-template #closeButton>
        <span>{{ 'Generic.labels.close' | translate }}</span>
      </ng-template>
    </button>
  </div>
</form>
