import {
  ScenarioFacadeService,
  UploadingStatusEnum,
} from 'prosumer-app/+scenario/state';
import { FileInputName, NameValidator } from 'prosumer-app/shared';
import { ScenarioStore } from 'prosumer-app/stores/scenario';
import { debounceTime, filter } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';

import { Scenario, VALID_SCENARIO_NAME_PATTERN } from '../../models';

const DEFAULT_FILE_EXTS = '.xls,.xlsx';
const DEFAULT_SCENARIO_TYPE = 'created_by_upload';

interface ScenarioInputTableData {
  scenarioName: string;
  fileName: string;
  statusIcon?: UploadingStatusEnum;
}

@Component({
  selector: 'prosumer-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFormComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<Scenario>();
  @Input() isLoading = false;
  @Input() columnDef: Record<string, Record<string, string>>;

  allowedFileExtns = DEFAULT_FILE_EXTS;
  tableData = signal<ScenarioInputTableData[]>([]);
  uploadForm = new FormArray([], [Validators.required]);

  constructor(
    // private translate: TranslateService,
    private readonly fb: UntypedFormBuilder,
    private readonly scenarioStoreNgrx: ScenarioStore,
    private readonly scenarioFacade: ScenarioFacadeService,
  ) {}

  ngOnInit(): void {
    this.subToFormChanges();
    this.subToUploadingStatus();
  }

  private subToUploadingStatus() {
    this.scenarioFacade.uploadingEntitiesStatus$
      .pipe(
        filter((d) => !!d),
        debounceTime(100),
      )
      .subscribe((d) => {
        this.tableData.update((list) =>
          list.map((e) =>
            e.scenarioName ? { ...e, statusIcon: d[e.scenarioName] } : e,
          ),
        );
      });
  }

  onSubmit(): void {
    if (this.uploadForm.valid) this.save.emit(this.uploadForm.value);
  }

  onCancel() {
    this.cancel.emit();
  }

  getXlsxlTemplate(): void {
    this.scenarioStoreNgrx.downloadXlsxlTemplate('getXlsxTemplate');
  }

  inputFilesChanged(files: File[]) {
    this.uploadForm.clear();
    files.forEach((f) => {
      const scenarioName = f?.name ? f.name.replace(/(?:.xlsx?)$/gi, '') : '';
      const upload = this.createGroupCtrl(f, scenarioName);
      this.uploadForm.push(upload);
    });
  }

  private createGroupCtrl(f: File, scenarioName: string) {
    return this.fb.group({
      name: [
        scenarioName,
        [Validators.required, NameValidator.validWithCore()],
      ],
      description: '',
      scenarioType: DEFAULT_SCENARIO_TYPE,
      fileInput: [
        f,
        [FileInputName.invalidCharacters(VALID_SCENARIO_NAME_PATTERN)],
      ],
    });
  }

  private subToFormChanges() {
    this.uploadForm.valueChanges.subscribe((v) => {
      this.tableData.set(this.mapFormValuesToTable(v));
    });
  }

  private mapFormValuesToTable(
    list: Record<string, any>[],
  ): ScenarioInputTableData[] {
    return list.map((v) => this.mapValueToRow(v));
  }

  private mapValueToRow(v: Record<string, any>): ScenarioInputTableData {
    return {
      fileName: v.fileInput.name,
      scenarioName: v.name,
    };
  }

  // getErrors(errorObj: any) {
  //   return errorObj ? getKeys(errorObj) : [];
  // }

  // inputNameErrorMessages(): FormFieldErrorMessageMap {
  //   return {
  //     required: this.translate.instant('Scenario.messages.name.required'),
  //     invalidCharacter: this.translate.instant(
  //       'Scenario.messages.name.invalidCharacter',
  //     ),
  //     whiteSpaces: this.translate.instant('Scenario.messages.name.whiteSpaces'),
  //   };
  // }

  // uploadFormErrorMessages(): FormFieldErrorMessageMap {
  //   return {
  //     required: this.translate.instant(
  //       'Scenario.messages.scenarioUploadAttachment.required',
  //     ),
  //     invalidCharacter: this.translate.instant(
  //       'Scenario.messages.scenarioUploadAttachment.invalidCharacter',
  //     ),
  //   };
  // }
}
