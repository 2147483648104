import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import _ from 'lodash';
import {
  SCENARIO_COMPARE_COLOR_REF,
  SCENARIO_RESULT_DISPATCH_TYPE_OPTIONS,
  generateShadedScheme,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  contains,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import {
  ActiveEntities,
  ActiveKeeperService,
} from 'prosumer-app/services/active-keeper';
import { AnalyticsService } from 'prosumer-app/shared';
import { ANALYTICS_SERVICE } from 'prosumer-app/shared/services/amplitude-analytics/analytics.tokens';
import { ProsumerRoutePathService } from 'prosumer-core';
import { ComparisonTabs, Result } from 'prosumer-scenario/models';
import { ScenarioCompareFacadeService } from 'prosumer-scenario/state';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import {
  filter,
  map,
  mergeMapTo,
  skip,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Case } from '../../../+case/models';
import { CaseFacadeService } from '../../../+case/state';

const DEFAULT_TAB_INDEX = 0;
const DISPATCH_TAB_INDEX = 6;
const SELECTABLE_TAB_INDEXES = [DISPATCH_TAB_INDEX];

@Component({
  selector: 'prosumer-scenario-compare-page',
  templateUrl: './scenario-compare-page.component.html',
  styleUrls: ['./scenario-compare-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
})
export class ScenarioComparePageComponent
  extends BaseComponent
  implements OnInit
{
  private tabIndex = new BehaviorSubject(DEFAULT_TAB_INDEX);
  private selectedResults = new BehaviorSubject<Array<Result>>([]);
  private _resultList: Array<Result>;
  private isOutputSplit: boolean;
  tabIndex$ = this.tabIndex.asObservable();
  selectedScenarios$ = this.selectedResults.asObservable();
  case$: Observable<Case> = this.getCaseObjectStream();
  selectedCase$ = this.caseFacade.selectedData$.pipe(this.takeUntilShare());

  constructor(
    private _scenarioCompareFacade: ScenarioCompareFacadeService,
    private caseFacade: CaseFacadeService,
    private perception: ResultsPerceptionService,
    @Inject(ANALYTICS_SERVICE) private analytics: AnalyticsService,
    private readonly activeKeeper: ActiveKeeperService,
    public router: Router,
    public routePath: ProsumerRoutePathService,
  ) {
    super();
  }

  /* Base */
  loading$ = this._scenarioCompareFacade.loading$.pipe(this.takeUntilShare());

  loaded$ = this._scenarioCompareFacade.loaded$.pipe(this.takeUntilShare());

  error$ = this._scenarioCompareFacade.error$.pipe(this.takeUntilShare());

  referenceId$ = this._scenarioCompareFacade.referenceId$.pipe(
    this.takeUntilShare(),
  );

  resultList$ = this._scenarioCompareFacade.resultList$.pipe(
    this.takeUntilShare(),
    tap((data) => {
      this._resultList = data;
    }),
  );

  chartScheme$ = this.resultList$.pipe(
    filter((scenarios) => !!scenarios && scenarios.length > 0),
    map((scenarios) => generateShadedScheme(scenarios.length)),
  );

  scenarioResultList$ = this._scenarioCompareFacade.scenarioResultList$.pipe(
    this.takeUntilShare(),
  );

  comparedScenariosFilter = {
    label: 'Compared Scenarios',
    placeholder: 'Compared Scenarios',
    value: 'All',
    options: [],
  };

  /* Derived */
  referenceScenario$ = this._scenarioCompareFacade.referenceScenario$.pipe(
    this.takeUntilShare(),
  );

  nonReferenceScenarios$ =
    this._scenarioCompareFacade.nonReferenceScenarios$.pipe(
      this.takeUntilShare(),
    );

  // Makes the reference scenario the first scenario in the list
  orderedScenarios$ = combineLatest([this.referenceId$, this.resultList$]).pipe(
    map(([referenceId, resultList]) =>
      resultList.sort((a) => (a.id === referenceId ? -1 : 1)),
    ),
    this.takeUntilShare(),
  );

  customColors$ = this.orderedScenarios$.pipe(
    map((scenarios) =>
      scenarios.map((scenario) => ({
        name: scenario.name,
        value: SCENARIO_COMPARE_COLOR_REF[scenario.colorClass],
      })),
    ),
    this.takeUntilShare(),
  );

  disabled$ = combineLatest([this.loading$, this.error$]).pipe(
    switchMap((data) => of(data[0] || data[1])),
    this.takeUntilShare(),
  );

  isOutputSplit$ = this._scenarioCompareFacade.isOutputSplit$.pipe(
    this.takeUntilShare(),
    tap((isSplit) => (this.isOutputSplit = isSplit)),
  );

  /* Main */
  mainResultList$ = combineLatest([
    this.orderedScenarios$,
    this._scenarioCompareFacade.referenceMainScenario$,
    this._scenarioCompareFacade.mainResultList$,
  ]).pipe(
    map(([orderedScenarios, refScenario, resultList]) =>
      [refScenario, ...resultList]
        .filter(
          ({ id, name }, idx, arr) =>
            idx ===
            arr.findIndex((value) => id === value.id && name === value.name),
        )
        .sort((a, b) =>
          orderedScenarios.findIndex((o) => o.name === a.name) >
          orderedScenarios.findIndex((o) => o?.name === b?.name)
            ? 1
            : -1,
        ),
    ),
    this.takeUntilShare(),
  );

  /* Equipment */
  equipmentResultList$ = this._scenarioCompareFacade.equipmentResultList$.pipe(
    this.takeUntilShare(),
  );

  equipmentEnergyVectorOptions$ =
    this._scenarioCompareFacade.equipmentEnergyVectorOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  equipmentNodeOptions$ =
    this._scenarioCompareFacade.equipmentNodeOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  equipmentTypeOptions$ =
    this._scenarioCompareFacade.equipmentTypeOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  /* Energy Balance */
  energyBalanceResultList$ =
    this._scenarioCompareFacade.energyBalanceResultList$.pipe(
      this.takeUntilShare(),
    );

  energyBalanceEnergyVectorOptions$ =
    this._scenarioCompareFacade.energyBalanceEnergyVectorOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  energyBalanceNodeOptions$ =
    this._scenarioCompareFacade.energyBalanceNodeOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  energyBalanceYearOptions$ =
    this._scenarioCompareFacade.energyBalanceYearOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  /* Topology */
  topologyResultList$ = this._scenarioCompareFacade.topologyResultList$.pipe(
    this.takeUntilShare(),
  );

  topologyOriginNodeOptions$ =
    this._scenarioCompareFacade.topologyOriginNodeOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  topologyDestinationNodeOptions$ =
    this._scenarioCompareFacade.topologyDestinationNodeOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  topologyEnergyVectorOptions$ =
    this._scenarioCompareFacade.topologyEnergyVectorOptions$.pipe(
      map((options) => options.map((option) => ({ value: option }))),
      this.takeUntilShare(),
    );

  /* Payback */
  paybackResultList$ = this._scenarioCompareFacade.paybackResultList$.pipe(
    this.takeUntilShare(),
  );

  /* Cash Flow */
  cashFlowResultList$ = this._scenarioCompareFacade.cashFlowResultList$.pipe(
    this.takeUntilShare(),
  );

  /* Dispatch */
  dispatchResultList$ = this._scenarioCompareFacade.dispatchResultList$.pipe(
    this.takeUntilShare(),
  );

  dispatchTypeOptions$ = this._scenarioCompareFacade.dispatchTypeOptions$.pipe(
    this.takeUntilShare(),
  );

  dispatchNodeOptions$ = this._scenarioCompareFacade.dispatchNodeOptions$.pipe(
    this.takeUntilShare(),
  );

  dispatchYearOptions$ = this._scenarioCompareFacade.dispatchYearOptions$.pipe(
    this.takeUntilShare(),
  );

  dispatchEnergyVectorOptions$ =
    this._scenarioCompareFacade.dispatchEnergyVectorOptions$.pipe(
      this.takeUntilShare(),
    );

  dispatchResultTypeOptions$ =
    this._scenarioCompareFacade.dispatchResultTypeOptions$.pipe(
      map((options) =>
        options
          .map((option) => ({
            name: SCENARIO_RESULT_DISPATCH_TYPE_OPTIONS[option],
            value: option,
          }))
          .sort(
            // Always keep storage at the last
            (a, b) => {
              if (a.value === b.value) {
                return 0;
              }
              if (a.value === 'storage_mvts') {
                return 1;
              }
              if (b.value === 'storage_mvts') {
                return -1;
              }
              return a.value > b.value ? -1 : 1;
            },
          ),
      ),
      this.takeUntilShare(),
    );

  dispatchTabSelected$ = this.tabIndex$.pipe(
    startWith(false),
    map((index) => (contains(SELECTABLE_TAB_INDEXES, index) ? true : false)),
    this.takeUntilShare(),
  );

  private getActiveParents(): ActiveEntities {
    return this.activeKeeper.getActiveEntities();
  }

  handleTabActions(tab: ComparisonTabs) {
    const projectId = this.getActiveParents().project;
    const caseId = this.getActiveParents().case;
    const resultList = this._resultList;

    if (tab === ComparisonTabs.main || !this.isOutputSplit) {
      return;
    }

    const tabsActions = {
      [ComparisonTabs.equipment]: this._scenarioCompareFacade.compareEquipment,
      [ComparisonTabs.energyBalance]:
        this._scenarioCompareFacade.compareEnergyBalance,
      [ComparisonTabs.topology]: this._scenarioCompareFacade.compareTopology,
      [ComparisonTabs.co2Emissions]:
        this._scenarioCompareFacade.compareCO2Emission,
      [ComparisonTabs.dispatch]: this._scenarioCompareFacade.compareDispatch,
      [ComparisonTabs.cashflow]: this._scenarioCompareFacade.compareCashFlow,
    };
    tabsActions[tab].bind(this._scenarioCompareFacade)(
      projectId,
      caseId,
      resultList,
    );
  }

  onTabChange(index: number) {
    switch (index) {
      case ComparisonTabs.equipment:
        this.analytics.trackCompareScenarioEquipment();
        break;
      case ComparisonTabs.topology:
        this.analytics.trackCompareScenarioTopology();
        break;
      case ComparisonTabs.cashflow:
        this.analytics.trackCompareScenarioCashFlow();
        break;
      case ComparisonTabs.co2Emissions:
        this.analytics.trackCompareScenarioCO2Emissions();
        break;
      case ComparisonTabs.energyBalance:
        this.analytics.trackCompareScenarioEnergyBalance();
        break;
      case ComparisonTabs.dispatch:
        this.analytics.trackCompareScenarioDispatch();
        break;
    }
    this.tabIndex.next(index);
    this.handleTabActions(index);
  }

  onSelectScenario(resultIds: Array<Result>) {
    this.selectedResults.next(resultIds);
  }

  onSelectComparedScenario(event) {
    if (event === 'All') {
      return;
    }
    const projectId = this.getActiveParents().project;
    const caseId = this.getActiveParents().case;
    this.router.navigate(
      this.routePath.scenarioResults(projectId, caseId, event),
    );
  }

  ngOnInit(): void {
    this.selectActiveCaseID().subscribe((caseId) => {
      this.perception
        .getPerceptionMapStream(caseId)
        .pipe(
          skip(1),
          this.takeUntilShare(),
          withLatestFrom(this.selectedCase$, this.loading$),
          filter(
            ([updatedPerception, selected, loading]) =>
              !!updatedPerception || selected.updating || !loading,
          ),
        )
        .subscribe(([updatedPerception, selected]) => {
          if (!_.isEqual(updatedPerception, selected.colorScheme)) {
            const updatedCase = { ...selected, colorScheme: updatedPerception };
            if (!!updatedCase && !!updatedCase.id && updatedCase.projectId) {
              this.caseFacade.updateChartColors(updatedCase);
            }
          }
        });
    });

    this.resultList$.subscribe();
    this.isOutputSplit$.subscribe();

    this.initComparedScenariosFilter();
  }

  initComparedScenariosFilter(): void {
    const comparedScenarios = [{ name: 'All', value: 'All' } as Result];
    this._resultList.forEach((result) => {
      if (!result.name.endsWith(']')) {
        comparedScenarios.push({
          ...result,
          value: result.scenarioId,
        } as Result);
      }
    });
    this.comparedScenariosFilter.options = comparedScenarios;
  }

  private getCaseObjectStream(): Observable<Case> {
    return this.selectActiveCaseID().pipe(
      tap((caseId) => this.caseFacade.selectId(caseId)),
      mergeMapTo(this.caseFacade.selectedData$),
    );
  }

  private selectActiveCaseID(): Observable<string> {
    return this.activeKeeper
      .selectScenarioParents()
      .pipe(map((parents) => parents.case));
  }
}
