<div
  fxLayout="row wrap"
  fxLayoutGap="10px"
  fxAlign="start"
  class="container-inputs"
  [formGroup]="secondaryForm"
>
  <prosumer-yearly-chart-input
    fxFlex="24%"
    inputLabel="Scenario.labels.energyGridConnections.tdbMargin"
    formControlName="margin"
    errorMessage="Scenario.messages.generic"
    contextHelpMsg="wizard_commodities.wizard_tdb_margin"
    [startYear]="yearList[0]"
    [endYear]="yearList[1]"
    data-testid="tdb-commodity-module-input-margin"
  ></prosumer-yearly-chart-input>

  <prosumer-yearly-chart-input
    fxFlex="24%"
    inputLabel="Scenario.labels.energyGridConnections.tdbTransportAndDistribution"
    formControlName="transpoAndDistrib"
    errorMessage="Scenario.messages.generic"
    contextHelpMsg="wizard_commodities.wizard_tdb_transpo_and_distrib"
    [startYear]="yearList[0]"
    [endYear]="yearList[1]"
    data-testid="tdb-commodity-module-input-transport-and-distribution"
  ></prosumer-yearly-chart-input>

  <prosumer-yearly-chart-input
    fxFlex="24%"
    inputLabel="Scenario.labels.energyGridConnections.tdbIndexation"
    formControlName="indexation"
    errorMessage="Scenario.messages.generic"
    contextHelpMsg="wizard_commodities.wizard_tdb_indexation"
    [startYear]="yearList[0]"
    [endYear]="yearList[1]"
    data-testid="tdb-commodity-module-input-indexation"
  ></prosumer-yearly-chart-input>

  <prosumer-yearly-chart-input
    fxFlex="24%"
    inputLabel="Scenario.labels.energyGridConnections.tdbTaxes"
    formControlName="taxes"
    errorMessage="Scenario.messages.generic"
    contextHelpMsg="wizard_commodities.wizard_tdb_taxes"
    [startYear]="yearList[0]"
    [endYear]="yearList[1]"
    data-testid="tdb-commodity-module-input-taxes"
  ></prosumer-yearly-chart-input>
  <div class="disabled-cover" [class.disabled]="secondaryForm.disabled"></div>
</div>
