import { Observable } from 'rxjs';
import { finalize, map, pluck, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { Equipment } from 'prosumer-app/+scenario/models';
import { EquipmentMapper } from 'prosumer-app/+scenario/services/mappers/equipment.mapper';
import {
  CascadeAction,
  CascadeController,
} from 'prosumer-app/services/cascade-controller';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { ScenarioGenericQuery } from '../scenario-generic';
import { EquipmentInfo } from './equipment.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.equipment, idKey: 'equipmentId' })
export class EquipmentStore extends CascadableDetailStore<EquipmentInfo> {
  constructor(
    api: StoreApiService,
    readonly cascader: CascadeController,
    private readonly generic: ScenarioGenericQuery,
  ) {
    super(api, cascader);
  }

  getEquipment(id: string, derType: string): Observable<EquipmentInfo> {
    return this.getSingle(ScenarioDetailType.equipment, id, {
      subType: derType,
    });
  }

  createEquipment(data: Equipment): Observable<unknown> {
    this.setLoading(true);
    const equipmentBE = this.toBE(data);
    const body = { ...equipmentBE, dataType: ScenarioDetailType.equipment };
    return this.handleAdd(
      this.post({ key: 'createDetail', data: {}, body }).pipe(
        map((equipment) => this.toFE(equipment)),
      ),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  updateEquipment(data: Equipment): Observable<unknown> {
    return this.cascadeEdit(data.id, data);
  }

  duplicateEquipment(data: Equipment): Observable<unknown> {
    this.upsertStatus(data.id, 'duplicating');
    return this.handleAdd(
      this.post({
        key: 'duplicateDetail',
        data: { equipmentId: data.id },
        body: {},
      }),
    );
  }

  getEquipments(): Observable<unknown> {
    this.setLoading(true);
    const data = { dataType: ScenarioDetailType.equipment };
    return this.handleAdd(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        pluck('details'),
        map((models: unknown[]) => this.beModelsToFE(models)),
      ),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  eradicateEquipment(id: string): Observable<unknown> {
    return this.confirmCascade(id, CascadeAction.delete).pipe(
      switchMap(() => this.doDelete(id)),
    );
  }

  getDataUuid(data: Record<string, unknown>): unknown {
    return data['equipmentId'];
  }

  toBE(from: unknown): EquipmentInfo {
    return EquipmentMapper.mapToBackend(from);
  }

  toFE(from: unknown): EquipmentInfo {
    const [startYear, endYear] = this.generic.getActivePeriod();
    const value = EquipmentMapper.mapToFrontend(from, startYear, endYear);
    return value;
  }

  private doDelete(id: string): Observable<unknown> {
    this.upsertLoading(id, true);
    return this.handleDelete(
      this.weirdDelete(id, this.buildWeirdDeleteParams(id)),
      id,
    ).pipe(finalize(() => this.upsertLoading(id, false)));
  }

  private buildWeirdDeleteParams(id: string): { subType: string } {
    return { subType: this.getEntity(id).type };
  }

  private beModelsToFE(models: unknown[]): EquipmentInfo[] {
    return models.map((model) => this.toFE(model));
  }

  private handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((data: EquipmentInfo) => this.add(data)));
  }

  private handleDelete(
    $: Observable<unknown>,
    equipmentId: string,
  ): Observable<unknown> {
    return $.pipe(tap(() => this.remove(equipmentId)));
  }
}
