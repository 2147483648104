<form [formGroup]="form" (ngSubmit)="onConfirm()">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div
      mat-dialog-title
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <h3 class="eyes-text-gray">
        <b>Horizon</b>
      </h3>

      <h1 fxLayout="row" fxLayoutAlign="center center">
        <b class="start-year">{{ data?.startYear || 'Start Year' }}</b>

        <mat-icon aria-hidden="false">chevron_right</mat-icon>

        <b class="end-year">{{ data?.endYear || 'End Year' }}</b>
      </h1>
    </div>

    <div
      mat-dialog-content
      class="intervals-container"
      fxLayout="column"
      fxLayoutGap="10px"
      formArrayName="intervals"
    >
      <div
        *ngFor="
          let interval of intervalsFormArray?.controls;
          let i = index;
          let l = last
        "
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start center"
        class="interval"
        [formGroupName]="i"
      >
        <span class="interval-label eyes-border-accent eyes-bg-accent"
          >Interval {{ i + 1 }}</span
        >

        <span>
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="From"
              formControlName="startYear"
              required
              (selectionChange)="onChangeInterval(interval?.getRawValue(), i)"
            >
              <mat-option
                *ngFor="let option of interval?.getRawValue()?.yearOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>

        <span>
          <mat-form-field appearance="outline">
            <mat-select placeholder="To" formControlName="endYear" required>
              <mat-option
                *ngFor="let option of interval?.getRawValue()?.yearOptions"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>

        <span class="delete-button">
          <button
            *ngIf="!!i"
            mat-icon-button
            type="button"
            color="warn"
            data-testid="energy-grid-limit-interval-dialog-delete-button"
            (click)="onDeleteInterval(interval?.getRawValue(), i)"
          >
            <mat-icon
              aria-hidden="false"
              aria-label="Delete"
              tooltipPosition="center"
              eyesTooltip="Delete"
              >remove_circle</mat-icon
            >
          </button>
        </span>
      </div>
    </div>

    <div
      class="button-container eyes-spacer-top"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        type="button"
        color="primary"
        mat-flat-button
        [disabled]="disableAdd"
        [attr.data-testid]="'yearly-market-limits-interval-add'"
        (click)="onAddInterval()"
      >
        <mat-icon aria-hidden="false">add</mat-icon>

        <span>Interval</span>
      </button>
    </div>
  </div>

  <div class="eyes-spacer-top" mat-dialog-actions fxLayout="row">
    <button
      type="submit"
      color="primary"
      mat-flat-button
      [attr.data-testid]="'yearly-market-limits-interval-confirm'"
      [disabled]="form?.invalid || form?.pristine"
    >
      {{ data?.confirm || 'OK' }}
    </button>

    <button mat-flat-button type="button" color="" (click)="onClose()">
      {{ data?.close || 'Cancel' }}
    </button>
  </div>
</form>
