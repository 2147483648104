<form [formGroup]="form" class="form-tab-group">
  <div class="tab-group-btn-container">
    <prosumer-xlsx-extractor-button
      [opts]="dataframeOpts"
      [disabled]="disabled || loading"
      [tooltipMessage]="'excel_extractor.dataframe'"
      (extractedValuesEvent)="handleDataframe($event)"
    ></prosumer-xlsx-extractor-button>
    <button
      data-testid="yearly-loads-interval"
      [disabled]="disabled || loading"
      mat-flat-button
      color="accent"
      (click)="onEditInterval()"
    >
      <mat-icon aria-hidden="false">edit</mat-icon>
      <span>Interval</span>
    </button>
  </div>
  <mat-tab-group
    #tabGroup
    formArrayName="intervals"
    [selectedIndex]="selected?.value"
    (selectedIndexChange)="onChangeSelectedTab($event)"
  >
    <mat-tab
      *ngFor="let interval of intervalsFormArray?.controls; let i = index"
      [disabled]="loading"
      [formGroupName]="i"
    >
      <ng-template matTabLabel>
        <div
          data-testid="yearly-loads-years"
          fxLayout="row"
          fxLayoutAlign="center center"
          [ngClass]="{ 'eyes-warn': isInvalid(i) }"
        >
          <b>{{ interval?.controls?.startYear?.value || 'Start Year' }}</b>
          <mat-icon aria-hidden="false">chevron_right</mat-icon>
          <b>{{ interval?.controls?.endYear?.value || 'End Year' }}</b>
          <span *ngIf="isInvalid(i)" class="eyes-warn">*</span>
        </div>
      </ng-template>

      <ng-template matTabContent>
        <prosumer-yearly-loads
          class="eyes-spacer-top"
          matInput
          formControlName="profile"
          [mode]="mode"
          [library]="libraries"
          [useLibraryPanel]="useLibraryPanel"
          [alwaysCustom]="alwaysCustom"
          [yearlyLoadEnabled]="yearlyLoadEnabled"
          [yearlyLoadMessage]="yearlyLoadMessages"
          [allowNegativeInput]="allowNegativeInput"
          [index]="i"
          [loading]="loading"
          [librarySelectionDisabled]="loading"
          [nodeOptions]="nodes$ | async"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [allowStringInput]="allowStringInput"
          (selectedLibraryChange)="onSelectLibrary($event)"
          (libraryFiltersChange)="onChangeLibraryFilters($event)"
          data-testid="yearly-loads"
        >
        </prosumer-yearly-loads>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <button id="btn" [hidden]="true"></button>
</form>
