<div *ngIf="shouldDisplay">
  <mat-form-field
    [ngClass]="class"
    [appearance]="appearance"
    [hideRequiredMarker]="hideRequired"
    [floatLabel]="floatLabel"
    [hintLabel]="hintLabel"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-select
      #select
      [attr.aria-label]="placeholder"
      [formControl]="control"
      [placeholder]="placeholder"
      [required]="required"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">{{
        option.name
      }}</mat-option>
    </mat-select>
    <mat-icon
      aria-hidden="false"
      *ngIf="tooltip"
      matSuffix
      class="eyes-input-tooltip-anchor"
      [prosumerTooltip]="tooltip"
      >help</mat-icon
    >
    <mat-hint>{{ hint }}</mat-hint>
    <mat-error
      *ngFor="let error of getErrors(control?.errors)"
      [innerHtml]="errorMessageMap[error]"
    >
    </mat-error>
  </mat-form-field>
</div>
