<form [formGroup]="form">
  <div *ngIf="useLibraryPanel" class="library-panel">
    <div
      class="eyes-radio-button-container"
      fxLayout="row"
      fxLayoutGap="15px"
      fxLayoutAlign="start center"
    >
      <label>
        {{ yearlyLoadMessage?.loadTypeLabel | translate }}
        <prosumer-tooltip-anchor
          *ngIf="yearlyLoadMessage?.loadTypeTooltip"
          [message]="yearlyLoadMessage?.loadTypeTooltip | translate"
          [attr.data-testid]="'yearly-load-type-tooltip'"
        ></prosumer-tooltip-anchor>
      </label>

      <mat-radio-group
        class="load-type"
        fxLayout="row"
        fxLayoutGap="15px"
        formControlName="loadType"
        data-testid="yearly-load-type-radio-group"
      >
        <mat-radio-button
          *ngFor="let option of loadTypeOptions"
          [value]="option?.value"
          [disabled]="alwaysCustom"
        >
          {{ option?.name }}
        </mat-radio-button>
      </mat-radio-group>

      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngIf="controls?.loadType.value === 'library'"
      >
        <mat-icon aria-hidden="false" ngClass="warning">warning</mat-icon>
        <div class="library-warning">
          Warning: you selected a library for the profile but it might be
          slightly oversized compared to reality. Please use it carefully if
          it's for a business purpose.
        </div>
      </div>
    </div>

    <div
      *ngIf="controls?.loadType?.value === 'library'"
      class="library-selection"
      fxLayout="column"
      fxLayoutGap="10px"
    >
      <div
        *ngIf="(library$ | async)?.length > 0"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="space-around"
      >
        <!-- Vector Type -->
        <prosumer-filter-chips
          fxFlex="calc(20% - 10px)"
          filterBy="vectorType"
          filterTitle="Type"
          [dataSource]="library"
          (filters)="updateFilter($event, filterForm?.controls?.vectorType)"
          data-testid="vector-type-filter-chip"
        >
        </prosumer-filter-chips>

        <!-- Business Type -->
        <prosumer-filter-chips
          fxFlex="calc(20% - 10px)"
          filterBy="businessType"
          filterTitle="Business Type"
          [dataSource]="library"
          (filters)="updateFilter($event, filterForm?.controls?.businessType)"
          data-testid="business-type-filter-chip"
        >
        </prosumer-filter-chips>

        <!-- Building Type -->
        <prosumer-filter-chips
          fxFlex="calc(20% - 10px)"
          filterBy="buildingType"
          filterTitle="Building Type"
          [dataSource]="library"
          (filters)="updateFilter($event, filterForm?.controls?.buildingType)"
          data-testid="building-type-filter-chip"
        >
        </prosumer-filter-chips>

        <!-- Building Category -->
        <prosumer-filter-chips
          fxFlex="calc(20% - 10px)"
          filterBy="buildingCategory"
          filterTitle="Building Category"
          [dataSource]="library"
          (filters)="
            updateFilter($event, filterForm?.controls?.buildingCategory)
          "
          data-testid="building-category-filter-chip"
        >
        </prosumer-filter-chips>

        <!-- Location -->
        <prosumer-filter-chips
          fxFlex="calc(20% - 10px)"
          filterBy="location"
          filterTitle="Location"
          [dataSource]="library"
          (filters)="updateFilter($event, filterForm?.controls?.location)"
          data-testid="location-filter-chip"
        >
        </prosumer-filter-chips>
      </div>

      <prosumer-sparkline-table
        maxHeight="185px"
        [columnsDef]="columnsDef"
        [data]="library"
        [selected]="selectedLibraryId"
        [loading]="libraryLoading"
        [error]="libraryError"
        [messages]="messages"
        [hideAdd]="true"
        [hideEdit]="true"
        [hideDelete]="true"
        (selectionChanged)="onSelect($event)"
        data-testid="yearly-loads-table"
      >
      </prosumer-sparkline-table>

      <!-- Using class 'hidden' will use a special mat-form-field to be used when displaying only the error message -->
      <mat-form-field class="hidden">
        <input matInput formControlName="library" />
        <mat-error
          *ngIf="controls?.library?.errors?.required"
          [innerHTML]="'Scenario.messages.der.library.required' | translate"
        >
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div
    *ngIf="yearlyLoadEnabled"
    class="eyes-spacer-top yearly-load"
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <mat-form-field
      fxFlex="calc(25% - 10px)"
      class="eyes-maximize-width"
      appearance="outline"
    >
      <mat-label>
        {{ yearlyLoadMessage?.yearlyLoadLabel | translate }}
      </mat-label>

      <input
        class="yearly-load-input"
        [attr.data-testid]="'yearly-load-input'"
        matInput
        formControlName="yearlyLoad"
        required
        type="number"
        [placeholder]="yearlyLoadMessage?.yearlyLoadPlaceholder | translate"
      />

      <mat-icon
        aria-hidden="false"
        class="eyes-input-tooltip-anchor"
        matSuffix
        [prosumerTooltip]="yearlyLoadMessage?.yearlyLoadTooltip | translate"
        [attr.data-testid]="'yearly-load-input-tooltip'"
      >
        help
      </mat-icon>

      <mat-error
        *ngFor="let error of controls?.yearlyLoad?.errors | keyvalue"
        [ngSwitch]="error.key"
      >
        <div
          *ngSwitchCase="'required'"
          [innerHtml]="yearlyLoadMessage?.yearlyLoadRequired | translate"
        ></div>
        <div
          *ngSwitchCase="'mustBeGreaterThanZero'"
          [innerHtml]="yearlyLoadMessage?.yearlyLoadGreaterThanZero | translate"
        ></div>
      </mat-error>
    </mat-form-field>

    <div fxFlex="calc(25% - 10px)"></div>
    <div fxFlex="calc(25% - 10px)"></div>
    <div fxFlex="calc(25% - 10px)"></div>
  </div>
  <!-- LOAD PROFILE CHART-->
  <div
    class="eyes-spacer-top-half eyes-spacer"
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <prosumer-enhanced-loads-input
      formControlName="loadProfile"
      (dataLoadChanges)="onLoadChanges($event)"
      (dataErrorsOutput)="onLoadErrors($event)"
      (nodesUsedEmitter)="onEmittedNodes($event)"
      [allowStringInput]="allowStringInput"
      [allowNegativeInput]="allowNegativeInput"
      [nodeOptions]="nodes$ | async"
      [loadRequiredMessage]="
        'Scenario.messages.yearlyLoad.input.required' | translate
      "
      [panelLabel]="
        controls?.loadType?.value === 'custom'
          ? yearlyLoadMessage?.loadsDataLabelCustom
          : yearlyLoadMessage?.loadsDataLabelLibrary
      "
      [tooltipMessage]="yearlyLoadMessage?.loadsDataTooltip | translate"
      [customValidators]="loadCustomValidators"
      data-testid="enhanced-loads-input"
    ></prosumer-enhanced-loads-input>
    <prosumer-stacked-area-chart
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      hasRangeSlider="true"
      ceiling="365"
      minRange="0"
      maxRange="31"
      [seriesDataList]="loads$ | async"
      [from]="sliderInitMinValue"
      [to]="sliderInitMaxValue"
      (fromChange)="onMinChange($event)"
      (toChange)="onMaxChange($event)"
      [xAxisFormat]="chartXAxisFormat"
      [tooltipTitleFormat]="chartTooltipTitleFormat"
      [seriesTooltipTitleFormat]="chartTooltipTitleFormat"
      [sliderOptions]="dateFilter.sliderOptions$ | async"
      data-testid="yearly-loads-stacked-area-chart"
    >
    </prosumer-stacked-area-chart>
  </div>

  <button id="btn" [hidden]="true"></button>
</form>
