import { ModuleWithProviders, NgModule } from '@angular/core';
import { ANALYTICS_CONFIG, ANALYTICS_SERVICE } from './analytics.tokens';
import { AnalyticsConfig } from './analytics.model';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
})
export class AnalyticsModule {
  static forRoot(
    config: AnalyticsConfig,
  ): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        { provide: ANALYTICS_CONFIG, useValue: config },
        { provide: ANALYTICS_SERVICE, useClass: config.serviceClass },
      ],
    };
  }
}
